// ========================================================================
// THEME/COLORS
// ========================================================================
const colors = {
    darkPetrol: '#002E3C',
    lightPetrol: '#00414B',
    tertiaryGreen: '#2D6069',
    quaternaryGreen: '#164952',
    white: '#ffffff',
    secondaryWhite: '#F2F6F6',
    yellow: '#FFD700',
    grey: '#D5E1E2',
    lightGrey: '#EBF0F0',
    darkGrey: '#537D82',
    lightGraySecondary: '#D5E1E2',
    scalesLight: '#A3BCC1',
    strongLine: '#799BA0',
    red: '#FF3442',
    orange: '#FFC800',
    hoverYellow: '#FFD619',
    bluePrimary: '#E5ECED',
    lightGreen: '#00B140',
    darkGreen: '#009E39',
    electricGreen: '#00cc00',
};
export default colors;
