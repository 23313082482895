import React from 'react';
import { interaction___close as CloseIcon } from '@lsg/icons';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { ReusableSvg } from 'components/ReusableSvg';
import { globalMessages } from 'i18n/messages';
import { FlexContainer } from 'shared/styled/Layout';
import { ToastMessageWrapper, ToastMessageText, ToastIconWrapper, ToastCloseIconWrapper, ToastMessageTitle, } from './ToastMessage.styles';
export const ToastMessage = ({ variant, message, icon, closeToast, }) => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    return (React.createElement(ToastMessageWrapper, null,
        React.createElement(ToastIconWrapper, { variant: variant }, React.cloneElement(icon, { width: '24px', height: '24px' })),
        React.createElement(FlexContainer, { direction: "column", alignItems: "flex-start", justifyContent: "space-between", padding: theme.spacing(1) },
            React.createElement(ToastMessageTitle, null, formatMessage(globalMessages[variant])),
            React.createElement(ToastMessageText, null, message)),
        React.createElement(ToastCloseIconWrapper, { onClick: closeToast },
            React.createElement(ReusableSvg, { icon: CloseIcon, lsgIcon: true }))));
};
export default ToastMessage;
