// ========================================================================
// UTILS/TEXT-OVERFLOW
// ========================================================================
export const textOverflow = `
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const isOverflown = (element) => {
    if (!element) {
        return false;
    }
    const { clientWidth, clientHeight, scrollWidth, scrollHeight } = element;
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
};
