var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { useField } from 'formik';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { defaultComponents, defaultStyles } from '..';
export const FormikSelect = (_a) => {
    var { isMulti, closeMenuOnSelect, isClearable, isSearchable = false, variantConfig = {
        menuBackground: 'white',
        inputBackground: 'white',
        size: 'small',
    }, isCreatableSelect = false, captureMenuScroll = false, // https://github.com/JedWatson/react-select/issues/4129 - in order to have scrollable list
    components, styles, name = '', defaultMenuIsOpen = false, onChange, onMenuOpen, onMenuClose, showArrowDownIcon = true } = _a, props = __rest(_a, ["isMulti", "closeMenuOnSelect", "isClearable", "isSearchable", "variantConfig", "isCreatableSelect", "captureMenuScroll", "components", "styles", "name", "defaultMenuIsOpen", "onChange", "onMenuOpen", "onMenuClose", "showArrowDownIcon"]);
    const Select = isCreatableSelect ? CreatableSelect : ReactSelect;
    const [isSelectOpen, setIsSelectOpen] = useState(defaultMenuIsOpen);
    const [, { value, initialValue, error }, { setValue, setTouched }] = useField({ name });
    const handleChange = (val, actionMeta) => {
        setValue(val.value);
        setTouched(true, false);
        setIsSelectOpen(val === null || val === void 0 ? void 0 : val.keepOpen);
        onChange === null || onChange === void 0 ? void 0 : onChange(val, actionMeta);
    };
    const handleMenuOpen = () => {
        setIsSelectOpen(true);
        onMenuOpen === null || onMenuOpen === void 0 ? void 0 : onMenuOpen();
    };
    const handleMenuClose = () => {
        setIsSelectOpen(value === null || value === void 0 ? void 0 : value.keepOpen);
        onMenuClose === null || onMenuClose === void 0 ? void 0 : onMenuClose();
    };
    return (React.createElement(Select, Object.assign({ components: Object.assign(Object.assign({}, defaultComponents), components), styles: Object.assign(Object.assign({}, defaultStyles), styles), isMulti: isMulti, closeMenuOnSelect: closeMenuOnSelect, isSearchable: isSelectOpen && isSearchable, isClearable: isClearable, captureMenuScroll: captureMenuScroll, variantConfig: variantConfig, value: value || initialValue, onChange: !props.isDisabled ? handleChange : undefined, error: !!error, errorText: error, defaultMenuIsOpen: defaultMenuIsOpen, menuIsOpen: isSelectOpen, onMenuOpen: handleMenuOpen, onMenuClose: handleMenuClose, controlShouldRenderValue: isSearchable || isCreatableSelect ? !isSelectOpen : true, showArrowDownIcon: showArrowDownIcon }, props)));
};
export default FormikSelect;
