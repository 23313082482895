import styled, { css } from 'styled-components';
export const InputCheckbox = styled.input `
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;
export const CheckboxLabel = styled.label `
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	font-size: ${({ theme }) => theme.fontSizes[15]};
	color: ${({ theme, hasError }) => (hasError ? theme.colors.red : 'inherit')};

	&::after {
		content: '';
		height: 0;
		width: 15px;
	}
	${({ checked, theme }) => checked &&
    css `
			font-weight: ${theme.typography.fontWeights.bold};
		`}
`;
