import React from 'react';
import { useField } from 'formik';
import { ReactComponent as Checked } from 'assets/icons/check.svg';
import { CheckboxLabel, InputCheckbox } from 'components/Checkbox/Checkbox.styles';
import { DecorativeCheckbox } from 'shared/styled/Inputs';
export const CheckboxFormik = ({ label, name, disabled, hasError, onToggle, }) => {
    const [field, _, { setValue }] = useField({ name });
    return (React.createElement(React.Fragment, null,
        React.createElement(CheckboxLabel, { checked: field.value, hasError: hasError, title: label },
            React.createElement(InputCheckbox, Object.assign({ type: "checkbox", checked: field.value }, field, { onChange: (event) => {
                    setValue(event.target.checked);
                    onToggle === null || onToggle === void 0 ? void 0 : onToggle(event.target.checked);
                }, disabled: disabled })),
            React.createElement(DecorativeCheckbox, { checked: field.value, hasError: hasError, "aria-hidden": "true" }, field.value && React.createElement(Checked, null)),
            label)));
};
export default CheckboxFormik;
