import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'components';
import { globalMessages } from 'i18n/messages';
import { ButtonsWrapper } from 'shared/styled/Layout';
export const DefaultModalButtons = ({ closeModal, actionHandler = () => null, closeButtonText = globalMessages.cancel, actionButtonText = globalMessages.continue, actionButtonType = 'button', actionButtonDisabled = false, closeButtonVariant = 'secondary', actionButtonVariant = 'primary', }) => {
    const { formatMessage } = useIntl();
    return (React.createElement(ButtonsWrapper, { marginTop: "3.5rem" },
        React.createElement(Button, { onClick: closeModal, variant: closeButtonVariant }, formatMessage(closeButtonText)),
        React.createElement(Button, { onClick: actionHandler, type: actionButtonType, disabled: actionButtonDisabled, variant: actionButtonVariant }, formatMessage(actionButtonText))));
};
export default DefaultModalButtons;
