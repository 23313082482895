import React, { useRef, useState, useLayoutEffect } from 'react';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import { ToggleSwitchItem, ToggleSwitchWrapper } from 'components/ToggleSwitch/ToggleSwitch.styles';
export const FormikToggleSwitch = ({ leftLabel, rightLabel, name, options, disabled = false, }) => {
    const { formatMessage } = useIntl();
    const leftChoice = useRef(null);
    const [, { value }, { setValue, setTouched }] = useField({ name });
    const [leftWidth, setLeftWidth] = useState(0);
    // @ts-ignore
    const firstItemActive = value === options[0] || !(options === null || options === void 0 ? void 0 : options.includes(value)); // @TODO remove ts-ignore
    const switchHandler = () => {
        setValue(value === options[0] ? options[1] : options[0]);
        setTouched(true, false);
    };
    useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            var _a, _b;
            if (entries[0]) {
                setLeftWidth((_b = (_a = entries[0]) === null || _a === void 0 ? void 0 : _a.borderBoxSize) === null || _b === void 0 ? void 0 : _b[0].inlineSize);
            }
        });
        leftChoice.current && resizeObserver.observe(leftChoice.current);
        return () => {
            leftChoice.current && resizeObserver.unobserve(leftChoice.current);
        };
    }, []);
    return (React.createElement(ToggleSwitchWrapper, { onClick: !disabled ? switchHandler : undefined, secondarySelected: !firstItemActive, leftToggleWidth: leftWidth, disabled: disabled },
        React.createElement(ToggleSwitchItem, { ref: leftChoice, active: firstItemActive }, formatMessage(leftLabel)),
        React.createElement(ToggleSwitchItem, { active: !firstItemActive }, formatMessage(rightLabel))));
};
export default FormikToggleSwitch;
