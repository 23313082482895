var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useRef, useLayoutEffect } from 'react';
import { isOverflown } from 'assets/styles/utils/text-overflow';
import { StyledButton } from './Button.styles';
const Button = (_a) => {
    var { type = 'button', variant = 'primary', small = false, fullWidth = false, disabled = false, children, hidden, 'data-testid': dataTestId = 'button-test-id' } = _a, htmlAttributes = __rest(_a, ["type", "variant", "small", "fullWidth", "disabled", "children", "hidden", 'data-testid']);
    const buttonRef = useRef(null);
    const [isElementOverflown, setIsElementOverflown] = useState(false);
    useLayoutEffect(() => {
        const handler = () => {
            setIsElementOverflown(isOverflown(buttonRef.current));
        };
        handler();
        document.addEventListener('resize', handler);
        return () => document.removeEventListener('resize', handler);
    }, [buttonRef]);
    return (React.createElement(StyledButton, Object.assign({ ref: buttonRef, type: type, variant: variant, small: small, fullWidth: fullWidth, disabled: disabled, "data-testid": dataTestId, hidden: hidden, isOverflown: isElementOverflown }, htmlAttributes), children));
};
export default Button;
