import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { Header, Toast } from 'components';
import { ROUTES_WITH_CONTACT_US } from 'constants/routes';
import { LayoutContainer } from 'shared/styled';
import { Footer, Navigation, ContactUsView } from 'views';
export const LayoutProvider = ({ children }) => {
    const { pathname } = useLocation();
    return (React.createElement(LayoutContainer, null,
        React.createElement(Toast, null),
        React.createElement(Header, null),
        React.createElement(Navigation, null),
        React.createElement(AnimatePresence, { mode: "wait" }, children),
        ROUTES_WITH_CONTACT_US.includes(pathname) && React.createElement(ContactUsView, null),
        React.createElement(Footer, null)));
};
export default LayoutProvider;
