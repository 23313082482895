import React from 'react';
import { connect } from 'react-redux';
import { loggerApiEndpoints } from 'api';
import { getUserQueryResult } from 'store';
import { pathnameToPageTitle, trackDataLayerEvent } from 'utils/googleAnalytics';
export const ErrorBoundary = class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError() {
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        const errMessage = `Error: ${error.toString()}.\n AdditionalInfo: ${JSON.stringify(errorInfo)}`;
        this.props.sendLog({
            level: 6,
            payload: errMessage,
            userId: this.props.userId,
        });
        trackDataLayerEvent({
            event: 'error',
            errorCode: null,
            errorMessage: errMessage,
            errorType: 'Unexpected error',
            eventContext: 'Digital Product Portal',
            pageId: location.pathname,
            pageTitle: pathnameToPageTitle(location.pathname),
            pageURL: location.pathname,
            corporateScope: this.props.authChannel,
        });
    }
    render() {
        if (this.state.hasError) {
            return this.props.fallback;
        }
        return this.props.children;
    }
};
const mapStateToProps = (state) => {
    var _a, _b;
    return {
        userId: (_b = (_a = getUserQueryResult(state).data) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '',
        authChannel: state.config.AUTH_CHANNEL,
    };
};
const mapDispatchToProps = (dispatch) => ({
    sendLog: (payload) => dispatch(loggerApiEndpoints.sendLog.initiate(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
