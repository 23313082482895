import React from 'react';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import { FormikToggle } from './FormikToggle';
import { StyledToggle, StyledToggleContainer, StyledToggleControl, StyledToggleInnerLabel, StyledToggleInput, StyledToggleLabel, StyledToggleOuterLabel, } from './Toggle.styles';
const BasicToggle = ({ name, checked, onChange, leftLabel, rightLabel, label, textColor, disabled = false, small = false, greyOutUnchecked = false, }) => {
    return (React.createElement(StyledToggleContainer, { disabled: disabled, "data-testid": "toggle-root", small: small },
        React.createElement(StyledToggleInput, { type: "checkbox", id: name, disabled: disabled, checked: checked, onChange: !disabled ? onChange : undefined }),
        React.createElement(StyledToggleLabel, { disabled: disabled, htmlFor: name, "data-testid": "label" },
            leftLabel && (React.createElement(StyledToggleInnerLabel, { textColor: textColor }, leftLabel)),
            React.createElement(StyledToggle, { greyedOut: !checked && greyOutUnchecked, borderWidth: 4 },
                React.createElement(StyledToggleControl, null, small && (checked ? React.createElement(CheckIcon, null) : React.createElement(CloseIcon, null)))),
            rightLabel && (React.createElement(StyledToggleInnerLabel, { textColor: textColor }, rightLabel))),
        label && React.createElement(StyledToggleOuterLabel, { htmlFor: name }, label)));
};
const Toggle = Object.assign(BasicToggle, { Formik: FormikToggle });
export default Toggle;
