import { styled } from 'styled-components';
export const ContactUsWrapper = styled.div `
	position: fixed;
	bottom: ${({ theme }) => theme.spacing(4.5)};
	left: ${({ theme }) => theme.spacing(1)};
	cursor: pointer;
	z-index: ${({ theme }) => theme.zIndex.toolbar};
`;
export const BusinessHoursInfoWrapper = styled.div ``;
export const BusinessHoursContainer = styled.span `
	display: flex;
	flex-direction: column;
	font-weight: 600;
	row-gap: ${({ theme }) => theme.spacing(2)};
	padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(1)}`};
`;
