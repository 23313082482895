import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { footerLinks } from 'constants/links';
import { globalMessages } from 'i18n/messages';
import { configSelector, userSelector } from 'store';
import { pathnameToPageTitle, trackDataLayerEvent } from 'utils/googleAnalytics';
import { AUTH_CHANNEL_MAPPER_REVERSED } from 'utils/mappers';
import { StyledFooter, StyledFooterText, StyledLinksWrapper, StyledFooterLink, } from './Footer.styles';
export const Footer = () => {
    const { formatMessage } = useIntl();
    const location = useLocation();
    const { ENABLE_GOOGLE_ANALYTICS, AUTH_CHANNEL } = useSelector(configSelector);
    const { language } = useSelector(userSelector);
    const trackLinkClick = (href, hrefLabel) => {
        trackDataLayerEvent({
            event: 'linkClick',
            contentUpdateDate: null,
            corporateScope: AUTH_CHANNEL_MAPPER_REVERSED[AUTH_CHANNEL],
            eventContext: 'Digital Product Portal',
            language: language,
            linkLabel: hrefLabel,
            pageId: location.pathname,
            pageLayout: 'desktop',
            pageReleaseDate: null,
            pageTemplate: null,
            pageTitle: pathnameToPageTitle(location.pathname),
            pageType: 'Antragsstrecke',
            pageURL: window.location.href,
            targetId: null,
            targetURL: href,
        });
    };
    const toggleConsentManagementModal = () => {
        var _a, _b;
        try {
            if ('toggleSettingsAreVisible' in window.cookieBox && // @TODO add stronger types in index.d.ts to avoid excessive checks
                typeof window.cookieBox.toggleSettingsAreVisible === 'function') {
                (_b = (_a = window === null || window === void 0 ? void 0 : window.cookieBox) === null || _a === void 0 ? void 0 : _a.toggleSettingsAreVisible) === null || _b === void 0 ? void 0 : _b.call(_a);
            }
        }
        catch (err) {
            console.error('Failed to open consent management modal!');
        }
    };
    return (React.createElement(StyledFooter, null,
        React.createElement(StyledLinksWrapper, null,
            footerLinks.map((item) => (React.createElement(StyledFooterLink, { key: item.href, href: item.href, target: "_blank", onClick: () => {
                    trackLinkClick(item.href, formatMessage(item.text));
                } }, formatMessage(item.text)))),
            ENABLE_GOOGLE_ANALYTICS && (React.createElement(StyledFooterLink, { key: "consentManagementToggle", onClick: toggleConsentManagementModal }, formatMessage(globalMessages.cookiesManagement)))),
        React.createElement(StyledFooterText, null, formatMessage(globalMessages.theBankAtYourSide))));
};
export default Footer;
