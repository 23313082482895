export const HTTP = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
    PUT: 'PUT',
    PATCH: 'PATCH',
};
export const HTTP_STATUSES = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
};
export const SERVER_ERRORS = [
    HTTP_STATUSES.INTERNAL_SERVER_ERROR,
    HTTP_STATUSES.NOT_IMPLEMENTED,
    HTTP_STATUSES.BAD_GATEWAY,
    HTTP_STATUSES.SERVICE_UNAVAILABLE,
    HTTP_STATUSES.GATEWAY_TIMEOUT,
];
export const SERVER_UNAVAILABLE_HTTP_STATUSES = [
    HTTP_STATUSES.SERVICE_UNAVAILABLE,
    HTTP_STATUSES.GATEWAY_TIMEOUT,
];
