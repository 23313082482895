import differenceWith from 'lodash.differencewith';
import isEqual from 'lodash.isequal';
import { api, requester } from 'api/shared';
import { PORTALS_URLS_MAPPER } from 'utils/mappers';
const CONFIGURATION_ENDPOINTS = {
    getExternalUrlsConfig: () => '/configuration',
    getLegalLanguages: () => '/configuration/language',
    getCurrenciesGroups: () => '/configuration/currency',
    getSettlementLimitBand: () => '/configuration/settlementlimitband',
};
const configApi = api.injectEndpoints({
    endpoints: (build) => ({
        getExternalUrlsConfig: build.query({
            query: () => requester.get(CONFIGURATION_ENDPOINTS.getExternalUrlsConfig()),
            transformResponse: ({ options = [], }) => {
                const result = {};
                const missingUrls = differenceWith(Object.values(PORTALS_URLS_MAPPER), options === null || options === void 0 ? void 0 : options.map(({ name }) => name), isEqual);
                if (missingUrls.length) {
                    console.error(`Missing option names: ${missingUrls.join(', ')}`);
                }
                return options.reduce((optionsResult, { name, value }) => {
                    if (name) {
                        optionsResult[name] = value;
                    }
                    return optionsResult;
                }, result);
            },
        }),
        getLegalLanguages: build.query({
            query: () => requester.get(CONFIGURATION_ENDPOINTS.getLegalLanguages()),
        }),
        getCurrenciesGroups: build.query({
            query: () => requester.get(CONFIGURATION_ENDPOINTS.getCurrenciesGroups()),
        }),
        getSettlementLimitBandConfig: build.query({
            query: () => requester.get(CONFIGURATION_ENDPOINTS.getSettlementLimitBand()),
        }),
    }),
});
export const configApiEndpoints = configApi.endpoints;
export const { useGetExternalUrlsConfigQuery, useGetLegalLanguagesQuery, useGetCurrenciesGroupsQuery, useGetSettlementLimitBandConfigQuery, } = configApi;
