// ========================================================================
// GLOBAL/ELEMENTS/PAGE
// ========================================================================
// min-height - Ensures the page fills at least the entire height of the viewport
// font-size - Set to 100%, this allows the site to adapt to the font size being used by the browser instead of forcing this value on the user
// font-family -  Set to allow the font to cascade to all elements
// line-height -  For overall site line height and vertical rhytym, should be a unitless value
// color - Set for all text elements
// background-color - Set for page background colour
import { css } from 'styled-components';
export const page = css `
	html {
		min-height: 100%;
		font-family: ${({ theme }) => theme.typography.fontFamily};
		font-weight: ${({ theme }) => theme.typography.fontWeights.light};
		font-size: ${({ theme }) => theme.typography.fontSize};
		line-height: ${({ theme }) => theme.typography.lineHeight};
		color: ${({ theme }) => theme.colors.darkPetrol};
		background-color: ${({ theme }) => theme.colors.white};
	}

	body.overlay-active {
		position: relative;
		overflow: hidden;
		height: 100%;
	}
`;
