// ========================================================================
// GLOBAL/ELEMENTS/LINKS
// ========================================================================
import { css } from 'styled-components';
// Set text decoration
export const links = css `
	a {
		text-decoration: none;
	}
`;
