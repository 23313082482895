import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { ReactComponent as CautionIcon } from 'assets/icons/cautionSign.svg';
import { Button, Modal, ReusableSvg } from 'components';
import { globalMessages } from 'i18n/messages';
import { SectionHeading } from 'shared/styled/Texts';
import { userSelector } from 'store';
import { notificationSuccessHandler } from 'utils/notificationHandlers';
import { ReportProblemForm } from 'views';
import { SomethingWentWrongWrapper } from './SomethingWentWrong.styles';
export const SomethingWentWrong = () => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const { language } = useSelector(userSelector);
    const [isOpen, setIsOpen] = useState(false);
    const handleModalClose = () => setIsOpen(false);
    const onSubmit = () => {
        handleModalClose();
        notificationSuccessHandler(formatMessage(globalMessages.successForm));
    };
    return (React.createElement(SomethingWentWrongWrapper, null,
        React.createElement(ReusableSvg, { Icon: CautionIcon, marginLeft: "auto", marginRight: "auto", marginBottom: "28px" }),
        React.createElement(SectionHeading, null, formatMessage(globalMessages.somethingWentWrong)),
        React.createElement(Button, { onClick: () => setIsOpen(true) }, formatMessage(globalMessages.reportProblem)),
        React.createElement(Modal, { name: 'Something Went Wrong Modal', language: language, isOpen: isOpen, displayDefaultActionButtons: false, closeModal: handleModalClose, variant: "medium", heading: formatMessage(globalMessages.reportProblem), padding: theme.spacing(8) },
            React.createElement(ReportProblemForm, { onSubmit: onSubmit, closeModal: handleModalClose, infoText: formatMessage(globalMessages.whatWentWrong), placeholder: formatMessage(globalMessages.describeWhatYouIntendedToDo) }))));
};
export default SomethingWentWrong;
