import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { browserSelector, setNewHistoryItem, setRedirectTo, useAppDispatch } from 'store';
export const BrowserHistoryProvider = ({ children }) => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { redirectTo } = useSelector(browserSelector);
    useEffect(() => {
        // @ts-ignore @TODO review ignore
        dispatch(setNewHistoryItem(location.pathname));
        if (redirectTo === location.pathname) {
            dispatch(setRedirectTo(null));
        }
    }, [location.pathname]);
    return React.createElement(React.Fragment, null, children);
};
export default BrowserHistoryProvider;
