import styled, { css } from 'styled-components';
import { pxToRem } from 'assets/styles/utils/px-to-rem';
export const StyledToggleControl = styled.span `
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 100%;
	transition: ${({ theme }) => theme.transition('left')};
	width: ${pxToRem(22)};
	height: ${pxToRem(22)};
	background-color: ${({ theme }) => theme.colors.lightPetrol};

	svg {
		position: absolute;
		top: 4px;
		left: 4px;
		color: ${({ theme }) => theme.colors.lightPetrol};
	}
`;
export const StyledToggle = styled.span `
	display: inline-block;
	background-color: ${({ theme, greyedOut = false }) => greyedOut ? theme.colors.grey : theme.colors.yellow};
	position: relative;
	width: ${pxToRem(44)};
	height: ${pxToRem(22)};
	border-radius: ${pxToRem(20)};
	border: ${({ theme, borderWidth = 0, greyedOut = false }) => `${pxToRem(borderWidth)} solid ${greyedOut ? theme.colors.grey : theme.colors.yellow}`};
	box-sizing: content-box;
	transition: ${({ theme }) => css `
		${theme.transition('background-color')},
		${theme.transition('border-color')};
	`};
`;
export const StyledToggleInnerLabel = styled.span `
	font-size: ${({ theme }) => theme.fontSizes[15]};
	color: ${({ theme, textColor = theme.colors.lightPetrol }) => textColor};
	display: flex;
	align-items: center;
	white-space: nowrap;

	svg {
		transition: ${({ theme }) => theme.transition()};
	}

	&:first-child {
		margin-right: ${({ theme }) => theme.spacing(1)};
	}

	&:last-child {
		margin-left: ${({ theme }) => theme.spacing(1)};
	}
`;
export const StyledToggleOuterLabel = styled.label `
	margin-top: ${({ theme }) => theme.spacing(1)};
	transition: ${({ theme }) => theme.transition('color')};
`;
export const StyledToggleInput = styled.input `
	-webkit-appearance: none;
	-moz-appearance: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
	margin: 0;

	&:active,
	&:focus {
		outline: none;
	}
`;
export const StyledToggleLabel = styled.label `
	display: flex;
	align-items: center;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
export const StyledToggleContainer = styled.div `
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	${({ disabled }) => disabled &&
    css `
			opacity: 0.5;
		`};

	${StyledToggle} {
		width: ${({ small }) => small && pxToRem(30)};
		height: ${({ small }) => small && pxToRem(15)};
		border-radius: ${({ small }) => small && pxToRem(15)};

		${StyledToggleControl} {
			width: ${({ small }) => small && pxToRem(15)};
			height: ${({ small }) => small && pxToRem(15)};
		}
	}

	${StyledToggleInnerLabel} {
		&:first-child {
			margin-right: ${({ small, theme }) => small && theme.spacing(0.5)};
		}

		&:last-child {
			margin-left: ${({ small, theme }) => small && theme.spacing(0.5)};
		}
	}

	${StyledToggleInput}:not(:checked) + ${StyledToggleLabel} {
		${StyledToggleInnerLabel}:first-child {
			font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
		}

		${StyledToggleInnerLabel}:last-child svg {
			color: ${({ theme }) => theme.colors.darkGrey};
		}
	}

	${StyledToggleInput}:checked + ${StyledToggleLabel} {
		${StyledToggleInnerLabel}:last-child {
			font-weight: ${({ theme }) => theme.typography.fontWeights.bold};

			svg {
				color: ${({ theme }) => theme.colors.yellow};
			}
		}

		${StyledToggleInnerLabel}:first-child svg {
			color: ${({ theme }) => theme.colors.darkGrey};
		}

		${StyledToggleControl} {
			left: calc(100% - ${() => pxToRem(22)});
		}
	}
`;
