import React from 'react';
import { symbols___error as ErrorIcon, symbols___infoCircle as InfoIcon } from '@lsg/icons';
import get from 'lodash.get';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { ToastMessage, ReusableSvg } from 'components';
import { SERVER_UNAVAILABLE_HTTP_STATUSES } from 'constants/http';
import { DEFAULT_ERROR_MESSAGE, SERVER_UNAVAILABLE_TOAST_ID, SOMETHING_WENT_WRONG_TOAST_ID, } from 'constants/index';
import { LOCALES } from 'i18n/locales';
export const notificationErrorHandler = (error, language = LOCALES.ENGLISH, toastId) => {
    var _a;
    const errorStatus = get(error, 'status', -1) || get(error, 'data.status', -1);
    if (SERVER_UNAVAILABLE_HTTP_STATUSES.includes(errorStatus)) {
        return notificationAlertHandler(React.createElement(FormattedMessage, { id: 'Global.serverUnavailable' }), SERVER_UNAVAILABLE_TOAST_ID);
    }
    let message = '';
    if (typeof error !== 'string') {
        message =
            'data' in error
                ? ((_a = error.data.localTitle) === null || _a === void 0 ? void 0 : _a[language]) || error.data.title
                : DEFAULT_ERROR_MESSAGE;
    }
    else {
        message = error;
    }
    return toast((toastProps) => {
        return React.createElement(ToastMessage, Object.assign(Object.assign({}, toastProps), { variant: 'error', icon: React.createElement(ReusableSvg, { icon: ErrorIcon, lsgIcon: true }), message: message || DEFAULT_ERROR_MESSAGE }));
    }, {
        autoClose: false,
        toastId: message === DEFAULT_ERROR_MESSAGE ? SOMETHING_WENT_WRONG_TOAST_ID : toastId,
    });
};
export const notificationSuccessHandler = (message, toastId) => {
    return toast((toastProps) => {
        return React.createElement(ToastMessage, Object.assign(Object.assign({}, toastProps), { variant: 'success', icon: React.createElement(ReusableSvg, { icon: InfoIcon, lsgIcon: true }), message: message || DEFAULT_ERROR_MESSAGE }));
    }, {
        autoClose: 4000,
        toastId: toastId,
    });
};
export const notificationAlertHandler = (message, toastId) => {
    return toast((toastProps) => {
        return React.createElement(ToastMessage, Object.assign(Object.assign({}, toastProps), { variant: 'warning', icon: React.createElement(ReusableSvg, { icon: ErrorIcon, lsgIcon: true }), message: message || DEFAULT_ERROR_MESSAGE }));
    }, { autoClose: false, toastId: toastId });
};
