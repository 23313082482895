import { styled, css } from 'styled-components';
import { pxToRem } from 'assets/styles/utils';
export const StyledInput = styled.input `
	width: 100%;
	height: ${({ theme, hasGreenBackground, inputFieldHeight }) => inputFieldHeight || (hasGreenBackground ? theme.spacing(6) : theme.spacing(4))};
	background-color: transparent;
	border: 0;
	padding: 0;
	font-size: ${({ theme, fontSize, hasGreenBackground }) => fontSize || (hasGreenBackground ? theme.fontSizes[20] : theme.fontSizes[15])};
	font-weight: ${({ theme, fontWeight, hasGreenBackground }) => fontWeight ||
    (hasGreenBackground
        ? theme.typography.fontWeights.regular
        : theme.typography.fontWeights.medium)};
	color: ${({ theme, hasGreenBackground }) => hasGreenBackground && theme.colors.white};
	box-sizing: border-box;

	${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

	${({ hasIcon, theme }) => hasIcon && `padding-right: calc(${theme.spacing(4)} + ${pxToRem(15)});`}

	&::placeholder {
		color: ${({ theme, hasGreenBackground }) => hasGreenBackground ? theme.colors.grey : theme.colors.lightPetrol};
		opacity: ${({ fadedPlaceholder }) => (fadedPlaceholder ? 0.3 : 1)};
	}

	&::selection {
		background: ${({ theme }) => theme.colors.yellow}; /* WebKit/Blink Browsers */
	}

	&,
	&:hover,
	&:focus,
	&:active {
		border: 0;
		outline: 0;
	}
	&:disabled {
		color: ${({ theme }) => theme.colors.grey};
	}
`;
export const StyledLabelWrapper = styled.div `
	display: flex;
	align-items: baseline;
	position: absolute;
	top: ${({ hasTooltip }) => (hasTooltip ? pxToRem(24) : pxToRem(12))};
	left: ${({ theme }) => theme.spacing(0)};
	transform: ${({ hasTooltip }) => (hasTooltip ? 'unset' : 'translateY(50%)')};
	transition: ${({ theme }) => theme.transition()};

	${StyledInput}:focus + & {
		top: ${({ hasTooltip }) => (hasTooltip ? pxToRem(-2) : 0)};
		transform: ${({ hasTooltip }) => (hasTooltip ? 'unset' : 'translateY(0)')};
	}
	${({ active, hasTooltip }) => active &&
    css `
			top: ${hasTooltip ? pxToRem(-2) : 0};
			transform: ${hasTooltip ? 'unset' : 'translateY(0)'};
		`}
`;
export const StyledLabel = styled.label `
	font-size: ${({ theme, fontSize }) => fontSize || theme.fontSizes[15]};
	line-height: 1;
	opacity: ${({ theme }) => theme.opacity[70]};

	position: relative;
	top: ${({ hasTooltip }) => (hasTooltip ? pxToRem(-3) : 0)};
	background-color: ${({ theme, hasGreenBackground = false }) => hasGreenBackground ? theme.colors.lightPetrol : theme.colors.white};

	${StyledInput}:focus + & {
		opacity: ${({ theme }) => theme.opacity[100]};
	}
	${({ active, theme }) => active &&
    css `
			opacity: ${theme.opacity[100]};
		`}
	${({ disabled, hasGreenBackground, theme }) => {
    if (disabled) {
        return css `
				color: ${theme.colors.grey};
			`;
    }
    return css `
			color: ${hasGreenBackground ? theme.colors.white : theme.colors.lightPetrol};
		`;
}}
`;
export const StyledClearButton = styled.button `
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${pxToRem(20)};
	height: ${pxToRem(20)};
	background-color: inherit;
	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.colors.orange};
	}
`;
export const StyledInputContainer = styled.div `
	display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
	width: ${({ fullWidth, width }) => fullWidth ? '100%' : typeof width === 'number' ? `${width}px` : width};
	padding-top: ${({ hasLabel }) => hasLabel && pxToRem(13)};
	position: relative;
	box-sizing: unset;

	${({ disabled, readOnly }) => (disabled || readOnly) &&
    css `
			caret-color: transparent;
			cursor: not-allowed;
			& > * {
				pointer-events: none;
				caret-color: transparent;
				cursor: not-allowed;
			}
		`};

	&::before,
	&::after {
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
	}

	&::before {
		border-bottom: 2px solid
			${({ theme, hasGreenBackground }) => hasGreenBackground ? theme.colors.scalesLight : theme.colors.lightPetrol};
		width: 100%;
		z-index: 0;
	}

	&::after {
		border-bottom: 2px solid ${({ theme }) => theme.colors.orange};
		transition: ${({ theme }) => `${theme.transition('width', 'med')}, ${theme.transition('border-bottom')}`};
		width: 0%;
	}

	&:focus-within::after,
	&:hover::after {
		border-width: 2px;
		width: 100%;
	}

	&:focus-within {
		&::after {
			border-bottom: 3px solid ${({ theme }) => theme.colors.yellow};
		}
	}
	&:hover ${StyledLabel}, &:focus-within ${StyledLabel} {
		opacity: ${({ theme }) => theme.opacity[100]};
	}

	${({ hasError, theme }) => hasError &&
    css `
			${StyledLabel} {
				color: ${theme.colors.red};
			}

			&,
			&:focus-within {
				&::before,
				&::after {
					border-color: ${theme.colors.red};
				}
			}
		`}

	${({ disabled, theme }) => disabled &&
    css `
			&::before {
				border-bottom: 1px solid ${theme.colors.grey};
			}
			&:focus-within::after,
			&:hover::after {
				width: unset;
			}
			cursor: not-allowed;
		`}
`;
export const StyledEndIcon = styled.span `
	position: absolute;
	right: ${({ theme }) => theme.spacing(1)};
	top: 50%;
	transform: translateY(-50%);
	width: ${pxToRem(15)};
	height: ${pxToRem(15)};
	margin: 0;
	pointer-events: none;

	svg {
		vertical-align: middle;
		max-height: 100%;
	}
`;
export const StyledInputIconWrapper = styled.div `
	padding-left: ${({ theme }) => theme.spacing(0.5)};
`;
