import { useCallback, useEffect } from 'react';
import { KEYBOARD_BUTTONS } from 'constants/common';
import { PORTAL_ID } from 'constants/index';
export const useModal = (modalIsOpen, closeModal, dismissOnSubmit) => {
    const handleClickOutsideOfModal = (event) => {
        var _a, _b;
        event.stopPropagation();
        if (((_b = (_a = event.target) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.id) !== PORTAL_ID) {
            return;
        }
        closeModal();
    };
    const handleKeyDown = useCallback((e) => {
        const key = e.key;
        if (modalIsOpen && key === KEYBOARD_BUTTONS.ESCAPE) {
            closeModal();
        }
    }, [modalIsOpen, closeModal]);
    useEffect(() => {
        const portal = document.getElementById(PORTAL_ID);
        modalIsOpen && dismissOnSubmit && (portal === null || portal === void 0 ? void 0 : portal.addEventListener('submit', closeModal));
        portal === null || portal === void 0 ? void 0 : portal.addEventListener('reset', closeModal);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            portal === null || portal === void 0 ? void 0 : portal.removeEventListener('submit', closeModal);
            portal === null || portal === void 0 ? void 0 : portal.removeEventListener('reset', closeModal);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [dismissOnSubmit, modalIsOpen, closeModal, handleKeyDown]);
    return [handleClickOutsideOfModal];
};
