import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ErrorBoundary, RouteSwitch } from 'components';
import { AccessProvider, ConfigProvider, I18nProvider, LayoutProvider, ThemeProvider, GoogleAnalyticsProvider, BrowserHistoryProvider, } from 'providers';
import { reduxStore } from 'store';
import { SomethingWentWrong } from 'views';
export const App = () => {
    return (React.createElement(ReduxProvider, { store: reduxStore },
        React.createElement(ConfigProvider, null,
            React.createElement(GoogleAnalyticsProvider, null,
                React.createElement(BrowserHistoryProvider, null,
                    React.createElement(ThemeProvider, null,
                        React.createElement(I18nProvider, null,
                            React.createElement(ErrorBoundary, { fallback: React.createElement(SomethingWentWrong, null) },
                                React.createElement(AccessProvider, null,
                                    React.createElement(LayoutProvider, null,
                                        React.createElement(RouteSwitch, null)))))))))));
};
export default App;
