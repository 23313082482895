import { Icon } from '@lsg/components';
import styled, { css } from 'styled-components';
export const StyledLsgIcon = styled(Icon) ``;
export const SvgContainer = styled.span `
	height: auto;
	width: ${({ width = 'auto' }) => width};
	display: ${({ hidden = false }) => (hidden ? 'none' : 'inline-flex')};
	vertical-align: ${({ verticalAlign = 'initial' }) => verticalAlign};
	align-items: center;
	justify-content: center;
	margin: ${({ marginTop = '0px', marginBottom = '0px', marginRight = '0px', marginLeft = '0px', }) => `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`};
	opacity: ${({ opacity = 1 }) => opacity};
	cursor: ${({ cursor = 'default' }) => cursor};

	& svg {
		height: ${({ height = 'auto' }) => height};
		width: ${({ width = 'auto' }) => width};
		${({ fill }) => {
    return (fill &&
        css `
					fill: ${fill};
				`);
}}
	}

	${StyledLsgIcon} {
		height: ${({ height = 'auto' }) => height};
		width: ${({ width = 'auto' }) => width};
	}

	${({ hoverable, withYellowBackground, theme }) => hoverable &&
    css `
			&:hover {
				z-index: 10;
				opacity: 1;
				position: relative;
				cursor: pointer;

				& svg {
					background: transparent;
				}

				&::after {
					content: '';
					background-color: ${withYellowBackground
        ? theme.colors.lightPetrol
        : theme.colors.yellow};
					border-radius: 50%;
					position: absolute;
					width: 155%;
					height: 155%;
					z-index: -1;
				}
			}
		`}

	${({ pulsate }) => pulsate &&
    css `
			@keyframes pulsate {
				0% {
					transform: scale(1);
				}
				25% {
					transform: scale(0.9);
				}
				35% {
					transform: scale(0.8);
				}
				45% {
					transform: scale(1.2);
				}
				55% {
					transform: scale(0.8);
				}
				65% {
					transform: scale(1.2);
				}
				75% {
					transform: scale(0.8);
				}
				100% {
					transform: scale(1);
				}
			}

			animation-name: pulsate;
			animation-iteration-count: infinite;
			animation-duration: 2s;
		`}
`;
export const LsgSvgIconWrapper = styled.span `
	&& * {
		margin: unset;
	}
`;
