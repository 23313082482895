// ========================================================================
// ELEMENTS/TABLES
// ========================================================================
import { css } from 'styled-components';
export const tables = css `
	table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
	}

	table.table {
		table-layout: fixed;
		font-size: ${({ theme }) => theme.fontSizes[12]};

		tr {
			border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
		}

		tbody tr:hover {
			cursor: pointer;
			background-color: ${({ theme }) => theme.colors.lightGrey};
		}

		td {
			padding-top: ${({ theme }) => theme.spacing(1)};
			padding-bottom: ${({ theme }) => theme.spacing(1)};
		}

		th {
			text-align: left;
			font-weight: 600;
			padding-top: ${({ theme }) => theme.spacing(0.5)};
			padding-bottom: ${({ theme }) => theme.spacing(0.5)};
		}

		th:first-child,
		td:first-child {
			padding-left: ${({ theme }) => theme.spacing(3)};
		}

		th:last-child,
		td:last-child {
			padding-right: ${({ theme }) => theme.spacing(3)};
		}
	}
`;
