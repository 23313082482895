// ========================================================================
// THEME/TYPOGRAPHY
// ========================================================================
import { lineHeight } from 'assets/styles/settings';
export default {
    fontFamily: 'Gotham, arial, sans-serif',
    fontWeights: {
        light: 300,
        regular: 400,
        medium: 600,
        bold: 800,
    },
    fontSize: '100%',
    lineHeight: lineHeight,
};
