import styled, { css } from 'styled-components';
export const DecorativeCheckbox = styled.span `
	position: relative;
	display: inline-block;
	height: 28px;
	width: 28px;
	min-height: 28px;
	min-width: 28px;
	background: ${({ theme }) => theme.colors.white};
	border: 2.5px
		${({ theme, hasError }) => (hasError ? theme.colors.red : theme.colors.lightGraySecondary)}
		solid;
	margin-right: 8px;

	${({ checked, theme }) => checked &&
    css `
			border-color: ${theme.colors.yellow};
			background: ${theme.colors.yellow};
		`}

	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		stroke-width: 2.5px;
	}
`;
