// ========================================================================
// GLOBAL/BASE/WORD-WRAP
// ========================================================================
export const wordWrap = `
  * {
    &,
    &::before,
    &::after {
      word-wrap: auto-phrase;
      hyphens: manual;
    }
  }
`;
