import { IS_LOCALHOST } from 'constants/environments';
import { invalidateSession, setInactiveSince } from 'store/reducers';
import { getSessionDuration } from 'utils/common';
export const browserMiddleware = (api) => (next) => (action) => {
    if (action.type === '__rtkq/focused') {
        const { browser: { inactiveSince }, } = api.getState();
        if (inactiveSince && Date.now() - inactiveSince > getSessionDuration(IS_LOCALHOST)) {
            api.dispatch(invalidateSession());
        }
        // @TODO use onFocus.type from .d.ts file
        api.dispatch(setInactiveSince(null));
    }
    if (action.type === '__rtkq/unfocused') {
        // @TODO use onFocus.type from .d.ts file
        api.dispatch(setInactiveSince(Date.now()));
    }
    // @TODO implement onOnline onOffline
    return next(action);
};
