import styled, { css } from 'styled-components';
import { blinkingBorder, slideDown } from 'assets/styles/animations';
import { pxToRem } from 'assets/styles/utils';
import { scrollable } from 'utils/scrollable';
export const StyledSelectContainer = styled.div `
	position: relative;
	padding-top: ${pxToRem(13)};

	&:hover {
		opacity: ${({ isDisabled, reduceOpacity = true, theme }) => isDisabled && reduceOpacity ? theme.opacity[20] : theme.opacity[100]};
	}

	${({ isDisabled, reduceOpacity = true, theme }) => isDisabled &&
    css `
			cursor: not-allowed;
			opacity: ${reduceOpacity ? theme.opacity[20] : theme.opacity[100]};
			& > * {
				pointer-events: none;
				caret-color: transparent;
			}
		`}
`;
export const StyledLabelWrapper = styled.div `
	display: flex;
	align-items: center;
	position: absolute;
	z-index: 51;
	top: ${({ keepLabelAboveSelect, hasValue, menuIsOpen, tooltipText }) => keepLabelAboveSelect || hasValue || menuIsOpen ? (tooltipText ? pxToRem(-6) : 0) : pxToRem(20)};
	left: ${({ theme }) => theme.spacing(0)};
	transition: ${({ theme }) => `${theme.transition('top')}, ${theme.transition('font-size')}`};
`;
export const StyledSelectLabel = styled.span `
	position: relative;
	top: ${({ tooltipText }) => (tooltipText ? pxToRem(-2) : 0)};
	color: ${({ theme, variantConfig }) => (variantConfig === null || variantConfig === void 0 ? void 0 : variantConfig.inputBackground) === 'white' ? theme.colors.lightPetrol : theme.colors.white};
	font-size: ${({ theme, menuIsOpen, hasValue, variantConfig = { size: 'small' } }) => {
    const defaultFontSize = variantConfig.size === 'small'
        ? theme.fontSizes[15]
        : variantConfig.size === 'medium'
            ? theme.fontSizes[17]
            : theme.fontSizes[20];
    const reducedFontSize = variantConfig.size === 'small'
        ? theme.fontSizes[13]
        : variantConfig.size === 'medium'
            ? theme.fontSizes[15]
            : theme.fontSizes[17];
    return menuIsOpen || hasValue ? reducedFontSize : defaultFontSize;
}};
	white-space: nowrap;
	line-height: 1;
	z-index: 30;
	pointer-events: none;
	${({ theme, error }) => error &&
    css `
			color: ${theme.colors.red};
		`}
`;
export const StyledSelectControl = styled.div `
	position: relative;
	width: 100%;
	height: auto;
	min-height: ${({ theme }) => theme.spacing(4)};
	padding-left: ${({ theme }) => theme.spacing(0)};
	font-size: ${({ theme, variantConfig = { size: 'small' } }) => variantConfig.size === 'small'
    ? theme.fontSizes[15]
    : variantConfig.size === 'medium'
        ? theme.fontSizes[17]
        : theme.fontSizes[20]};

	font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
	background-color: ${({ theme, variantConfig = { inputBackground: 'white' } }) => variantConfig.inputBackground === 'white' ? theme.colors.white : theme.colors.lightPetrol};
	text-align: left;
	z-index: ${({ theme }) => theme.zIndex.buttonSelect};

	&:first-child {
		flex-direction: column;
		align-items: flex-start;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
	}

	&::before {
		z-index: 0;
		width: 100%;
		border-bottom-width: ${({ menuIsOpen }) => (menuIsOpen ? '3px' : '2px')};
		border-bottom-style: solid;

		border-bottom-color: ${({ theme, menuIsOpen, variantConfig }) => {
    return menuIsOpen
        ? theme.colors.yellow
        : (variantConfig === null || variantConfig === void 0 ? void 0 : variantConfig.inputBackground) === 'white'
            ? theme.colors.lightPetrol
            : theme.colors.white;
}};

		${({ enabledBlinkingBorder, hasValue, menuIsOpen }) => enabledBlinkingBorder &&
    hasValue === false &&
    menuIsOpen === false &&
    css `
				animation: ${blinkingBorder} 2s infinite;
			`}

		${({ theme, error }) => error &&
    css `
				border-bottom-color: ${theme.colors.red};
			`}
	}

	&::after {
		width: 0;
		border-bottom-width: ${({ menuIsOpen }) => (menuIsOpen ? '3px' : '2px')};
		border-bottom-style: solid;
		border-bottom-color: ${({ theme, error }) => (error ? theme.colors.red : theme.colors.orange)};
		transition: ${({ theme }) => `${theme.transition('width', 'med')}, ${theme.transition('border-bottom')}`};
	}

	&:hover,
	&:focus {
		&::after {
			width: ${({ menuIsOpen }) => (menuIsOpen ? 0 : '100%')};
		}
	}

	svg {
		position: absolute;
		top: 50%;
		right: ${({ theme }) => theme.spacing(1)};
		${({ menuIsOpen, theme }) => css `
			${theme.transition('transition')};
			${`transform: translate(0, -50%) ${menuIsOpen ? 'rotate(180deg);' : 'rotate(0);'}`}
		`}
		filter: ${({ menuIsOpen, variantConfig }) => menuIsOpen
    ? 'invert(81%) sepia(52%) saturate(919%) hue-rotate(359deg) brightness(100%) contrast(108%);'
    : (variantConfig === null || variantConfig === void 0 ? void 0 : variantConfig.inputBackground) === 'white'
        ? 'none;'
        : 'invert(100%) sepia(0%) saturate(0%) hue-rotate(82deg) brightness(105%) contrast(105%);'}
		${({ error }) => error &&
    css `
				filter: invert(37%) sepia(90%) saturate(3005%) hue-rotate(333deg) brightness(95%)
					contrast(112%);
			`}
	}
`;
export const StyledSelectMenu = styled.div `
	position: absolute;
	top: 100%;
	width: 100%;
	max-height: ${({ menuMaxHeight = '24vh' }) => typeof menuMaxHeight === 'number' ? `${menuMaxHeight}px` : menuMaxHeight};
	background: ${({ theme, variantConfig }) => (variantConfig === null || variantConfig === void 0 ? void 0 : variantConfig.menuBackground) === 'white' ? theme.colors.white : theme.colors.lightPetrol};
	box-shadow: ${({ theme }) => theme.boxShadow};
	animation: ${slideDown} ${({ theme }) => theme.durations.slow} forwards;
	z-index: ${({ theme }) => theme.zIndex.overlays};

	${({ theme, variantConfig }) => scrollable(undefined, undefined, (variantConfig === null || variantConfig === void 0 ? void 0 : variantConfig.menuBackground) === 'white'
    ? theme.colors.darkPetrol
    : theme.colors.hoverYellow)}
`;
export const StyledOption = styled.div `
	width: 100%;
	padding: ${({ theme }) => `${theme.spacing(1.25)} ${theme.spacing(1.5)}`};
	font-size: ${({ theme, variantConfig = { size: 'small' } }) => variantConfig.size === 'small'
    ? theme.fontSizes[12]
    : variantConfig.size === 'medium'
        ? theme.fontSizes[14]
        : theme.fontSizes[16]};

	text-align: left;
	background-color: ${({ theme, variantConfig = { menuBackground: 'white' } }) => variantConfig.menuBackground === 'petrol' ? theme.colors.lightPetrol : theme.colors.white};
	color: ${({ theme, variantConfig = { menuBackground: 'white' } }) => variantConfig.menuBackground === 'petrol' ? theme.colors.white : theme.colors.lightPetrol};
	background-color: ${({ theme, variantConfig = { menuBackground: 'white' }, isFocused }) => isFocused
    ? theme.colors.lightGrey
    : variantConfig.menuBackground === 'petrol'
        ? theme.colors.lightPetrol
        : theme.colors.white};
	font-weight: ${({ theme, isDisabled, isFocused }) => isFocused && !isDisabled && theme.typography.fontWeights.medium};
	&:hover,
	&:focus {
		background-color: ${({ theme, isDisabled }) => !isDisabled && theme.colors.lightGrey};
		font-weight: ${({ theme, isDisabled }) => !isDisabled && theme.typography.fontWeights.medium};
	}
`;
export const StyledSingleValue = styled.div `
	position: relative;
	width: 100%;
	color: ${({ theme, variantConfig = { inputBackground: 'white' } }) => variantConfig.inputBackground === 'petrol' ? theme.colors.white : theme.colors.lightPetrol};

	padding: ${({ theme }) => `${theme.spacing(0.5)} ${theme.spacing(2)} ${theme.spacing(0.5)} 0`};
	font-size: ${({ theme, variantConfig = { size: 'small' } }) => variantConfig.size === 'small'
    ? theme.fontSizes[15]
    : variantConfig.size === 'medium'
        ? theme.fontSizes[17]
        : theme.fontSizes[20]};

	font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
	background-color: transparent;
	text-align: left;
	white-space: break-spaces;
`;
export const ErrorMessage = styled.div `
	color: ${({ theme }) => theme.colors.red};
	font-size: ${({ theme }) => theme.fontSizes[12]};
`;
export const StyledSelectIconWrapper = styled.div `
	padding-left: ${({ theme }) => theme.spacing(0.5)};
	pointer-events: all;
`;
