import { styled, css } from 'styled-components';
import { slideInCentered } from 'assets/styles/animations';
import { scrollable } from 'utils/scrollable';
export const ModalWrapper = styled.div `
	display: ${({ isVisible = true }) => (isVisible ? 'block' : 'none')};
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background: rgba(0, 34, 40, 0.35);
	z-index: ${({ theme }) => theme.zIndex.modal};
`;
export const ModalContainer = styled.div `
	z-index: inherit;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: ${({ theme }) => theme.colors.white};
	min-height: ${({ minHeight }) => minHeight};
	max-height: 98%;

	${({ theme, variant }) => theme.modalSizes[variant]}
	animation: ${slideInCentered} ${({ theme }) => theme.durations.slow} forwards;
	box-shadow: 0px 16px 24px -8px rgba(0, 0, 0, 0.28);

	${({ padding }) => padding &&
    css `
			padding: ${padding};
		`}
`;
export const ModalContent = styled.div `
	height: ${({ height }) => height};
	padding: ${({ theme, padding }) => padding || theme.spacing(1)};
	${({ scrollableContent }) => scrollableContent && scrollable()}

	${({ html = false }) => html &&
    css `
			padding-right: ${({ theme }) => theme.spacing(2)};

			* {
				font-size: revert;
				margin: revert;
				font-weight: revert;
			}
		`}
`;
export const CloseIconWrapper = styled.div `
	position: absolute;
	right: ${({ theme }) => theme.spacing(1.5)};
	top: ${({ theme }) => theme.spacing(2)};
	cursor: pointer;
	width: 17px;
	height: 17px;
	z-index: ${({ theme }) => theme.zIndex.icon};

	${({ notAlignedWithHeading }) => notAlignedWithHeading &&
    css `
			top: 27px;
		`}
`;
export const ModalHeadingWrapper = styled.div `
	margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
export const ModalHeading = styled.h2 `
	text-align: center;
	font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
	font-size: ${({ theme }) => theme.fontSizes[24]};
	line-height: normal;
`;
