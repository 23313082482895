import { styled } from 'styled-components';
export const ProgressBarWrapper = styled.div `
	display: grid;
	grid-template-columns: ${({ width }) => `auto minmax(auto, ${width}px)`};
	align-items: center;
	column-gap: ${({ onlyPercentLabel }) => (onlyPercentLabel ? '8px' : '24px')};
`;
export const ProgressBarText = styled.h3 `
	font-size: ${({ theme }) => theme.fontSizes[15]};
	font-weight: ${({ theme }) => theme.typography.fontWeights.bold};

	color: ${({ textColor }) => textColor};
`;
export const ProgressBarElement = styled.div `
	height: 8px;
	background-color: ${({ theme, background }) => background === 'grey' ? theme.colors.lightGrey : theme.colors.lightPetrol};
	border-radius: 8px;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		width: ${({ filled = 0 }) => `${filled}%`};
		height: 100%;
		border-radius: 8px;
		background-color: ${({ theme, color }) => color === 'yellow' ? theme.colors.yellow : theme.colors.darkGrey};
	}
`;
