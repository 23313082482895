import { HTTP } from 'constants/http';
const requestBuilder = (url, method, data, params) => {
    switch (method) {
        case HTTP.GET:
            return {
                url,
                params,
            };
        case HTTP.POST:
        case HTTP.PUT:
        case HTTP.PATCH:
        case HTTP.DELETE:
            return {
                url,
                params,
                method,
                body: data,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            };
    }
};
export const requester = {
    get: (url, params) => requestBuilder(url, HTTP.GET, undefined, params),
    post: (url, data, params) => requestBuilder(url, HTTP.POST, data, params),
    delete: (url, data, params) => requestBuilder(url, HTTP.DELETE, data, params),
    put: (url, data, params) => requestBuilder(url, HTTP.PUT, data, params),
    patch: (url, data, params) => requestBuilder(url, HTTP.PATCH, data, params),
};
export const invalidateOn = ({ success = [], error = [], invalidateOnErrorStatuses, }) => {
    return (_, errorFromApi) => {
        if (!errorFromApi) {
            return success;
        }
        const errorStatus = errorFromApi instanceof Object && 'status' in errorFromApi
            ? errorFromApi.status
            : -1;
        if (!invalidateOnErrorStatuses || invalidateOnErrorStatuses.includes(errorStatus)) {
            return error;
        }
        return [];
    };
};
