var _a;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { jwtDecode } from 'jwt-decode';
import { IS_LOCALHOST } from 'constants/environments';
import { CLIENT_FORMS, GET_USER } from 'constants/index';
const REDUCER_PATH = 'api';
const apiBaseQuery = fetchBaseQuery({
    baseUrl: IS_LOCALHOST
        ? `${process.env.BASE_URL}${process.env.API_VERSION}`
        : `${(_a = window === null || window === void 0 ? void 0 : window._env_) === null || _a === void 0 ? void 0 : _a.BASE_URL}${process.env.API_VERSION}`,
    prepareHeaders: (headers, { getState }) => {
        var _a, _b;
        const { authToken, sessionValid } = getState().auth;
        if (!authToken || !sessionValid) {
            return headers;
        }
        const tokenExpirationTimestamp = (_b = (_a = jwtDecode(authToken)) === null || _a === void 0 ? void 0 : _a.exp) !== null && _b !== void 0 ? _b : 0;
        const tokenValid = Date.now() < tokenExpirationTimestamp * 1000;
        if (tokenValid) {
            headers.set('Authorization', `Bearer ${authToken}`);
        }
        return headers;
    },
});
export const api = createApi({
    reducerPath: REDUCER_PATH,
    baseQuery: apiBaseQuery,
    tagTypes: [CLIENT_FORMS, GET_USER],
    endpoints: () => ({}),
});
