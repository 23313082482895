var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, forwardRef } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { Layer, ReusableSvg } from 'components';
import { StyledError } from 'shared/styled/Layout';
import { FormikInput } from './FormikInput';
import { StyledClearButton, StyledEndIcon, StyledInput, StyledInputContainer, StyledInputIconWrapper, StyledLabel, StyledLabelWrapper, } from './Input.styles';
// eslint-disable-next-line react/display-name
const BasicInput = forwardRef((props, ref) => {
    // eslint-disable-next-line react/prop-types
    const { label, id, error, value, endIcon, clearIcon = React.createElement(CloseIcon, { width: "75%", height: "75%" }), onClear, children, className = '', hasGreenBackground, name, disabled = false, readOnly = false, onChange, tooltipText, inputFieldHeight, variant = 'primary', autoComplete = 'disabled', numbersOnlyField = false, fadedPlaceholder = false, showError = true, fullWidth = false, width = 'auto', hidden, mask, replacePattern } = props, rest = __rest(props, ["label", "id", "error", "value", "endIcon", "clearIcon", "onClear", "children", "className", "hasGreenBackground", "name", "disabled", "readOnly", "onChange", "tooltipText", "inputFieldHeight", "variant", "autoComplete", "numbersOnlyField", "fadedPlaceholder", "showError", "fullWidth", "width", "hidden", "mask", "replacePattern"]);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const toggleTooltipOpenState = () => {
        setIsTooltipOpen(!isTooltipOpen);
    };
    const handleChange = (event) => {
        var _a;
        if (disabled || readOnly) {
            return;
        }
        let newValue = event.target.value;
        if (replacePattern) {
            newValue = (_a = String(newValue)) === null || _a === void 0 ? void 0 : _a.replace(replacePattern, '');
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(newValue);
    };
    return (React.createElement(StyledInputContainer, { hasLabel: !!label, hasError: Boolean(showError && error), className: className, hasGreenBackground: hasGreenBackground, "data-testid": "input", disabled: disabled, readOnly: readOnly, isHidden: hidden, width: width, fullWidth: fullWidth },
        React.createElement(StyledInput, Object.assign({ id: id, value: numbersOnlyField && typeof value === 'string'
                ? value.replace(/[^0-9]/g, '')
                : mask
                    ? mask(String(value || ''))
                    : value, fadedPlaceholder: fadedPlaceholder, inputFieldHeight: inputFieldHeight, hasIcon: !!endIcon, disabled: disabled, onChange: handleChange, autoComplete: autoComplete }, rest, { ref: ref, hasGreenBackground: hasGreenBackground, name: name })),
        label && (React.createElement(StyledLabelWrapper, { active: true, hasTooltip: !!tooltipText },
            React.createElement(StyledLabel, { htmlFor: id, active: !!value, disabled: disabled, hasGreenBackground: hasGreenBackground, hasTooltip: !!tooltipText }, label),
            tooltipText && (React.createElement(StyledInputIconWrapper, null,
                React.createElement(ReusableSvg, { onClick: toggleTooltipOpenState, Icon: InfoIcon, width: "14px", height: "14px", hoverable: true }),
                React.createElement(Layer, { name: label, closeLinkLabel: label, open: isTooltipOpen, onCloseClick: toggleTooltipOpenState }, tooltipText))))),
        endIcon && React.createElement(StyledEndIcon, null, endIcon),
        !!onClear && React.createElement(StyledClearButton, { onClick: onClear }, clearIcon),
        children,
        showError && error && React.createElement(StyledError, null, error)));
});
const Input = Object.assign(BasicInput, { Formik: FormikInput });
export default Input;
