import { keyframes } from 'styled-components';
import { theme } from 'assets/styles/theme';
export const slideIn = keyframes `
	from {
        transform: translateY(-100%);
    }   
`;
export const slideInCentered = keyframes `
	from {
		transform: translate(-50%, -100%);
	}
`;
export const slideLeft = keyframes `
  0% { opacity: 0; transform: translateX(15px) }
  100% { opacity: 1; transform: translateY(0) }
`;
export const slideDown = keyframes `
  0% { opacity: 0; transform: translateY(-15px) }
  100% { opacity: 1; transform: translateY(0) }
`;
export const blinkingBorder = keyframes `
  0% {
      border-color: ${theme.colors.orange};
  }
  100% {
      border-color: ${theme.colors.electricGreen};
  }       
`;
