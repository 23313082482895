import React, { useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormikToggleSwitch } from './FormikToggleSwitch';
import { ToggleSwitchItem, ToggleSwitchWrapper } from './ToggleSwitch.styles';
export const BasicToggleSwitch = ({ leftLabel, rightLabel, leftIsActive, setLeftIsActive, disabled = false, }) => {
    const { formatMessage } = useIntl();
    const leftChoice = useRef(null);
    const [leftWidth, setLeftWidth] = useState(0);
    useLayoutEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            var _a, _b;
            if (entries[0]) {
                setLeftWidth((_b = (_a = entries[0]) === null || _a === void 0 ? void 0 : _a.borderBoxSize) === null || _b === void 0 ? void 0 : _b[0].inlineSize);
            }
        });
        leftChoice.current && resizeObserver.observe(leftChoice.current);
        return () => {
            leftChoice.current && resizeObserver.unobserve(leftChoice.current);
        };
    }, []);
    const switchHandler = () => {
        setLeftIsActive === null || setLeftIsActive === void 0 ? void 0 : setLeftIsActive(!leftIsActive);
    };
    return (React.createElement(ToggleSwitchWrapper, { onClick: !disabled ? switchHandler : undefined, secondarySelected: !leftIsActive, leftToggleWidth: leftWidth, disabled: disabled },
        React.createElement(ToggleSwitchItem, { active: leftIsActive, ref: leftChoice }, formatMessage(leftLabel)),
        React.createElement(ToggleSwitchItem, { active: !leftIsActive }, formatMessage(rightLabel))));
};
const ToggleSwitch = Object.assign(BasicToggleSwitch, { Formik: FormikToggleSwitch });
export default ToggleSwitch;
