import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactComponent as HomeIcon } from 'assets/icons/house.svg';
import { ProgressBar } from 'components';
import { ERRORED_ROUTES, ROUTES } from 'constants/index';
import { globalMessages, onboardingRequestsMessages, touristUsersMessages } from 'i18n/messages';
import { userSelector, authenticatedRoutesSelector, configSelector } from 'store';
import { pathnameToPageTitle, trackDataLayerEvent } from 'utils/googleAnalytics';
import { AUTH_CHANNEL_MAPPER_REVERSED, OVERALL_STATUS } from 'utils/mappers';
import { ApplicationNavigation, NavPlaceholder, NavigationItemsWrapper, StyledLink, } from './Navigation.styles';
export const Navigation = () => {
    const { formatMessage } = useIntl();
    const { pathname } = useLocation();
    const { id: userId, notSupported: userNotSupported, overallStatus: userOverallStatus, language, allRequestsTriggeredInternally, // by Sales i.e.
    allowRegisterInterest, } = useSelector(userSelector);
    const { AUTH_CHANNEL } = useSelector(configSelector);
    const canActivate = useSelector(authenticatedRoutesSelector);
    const [currentValue, setCurrentValue] = useState(0); // @TODO revisit
    const navDisabled = useMemo(() => !userId || userNotSupported, [userId, userNotSupported]); // @TODO revisit
    useEffect(() => {
        /* @TODO: fix the current value */
        pathname === ROUTES.LANDING_PAGE && setCurrentValue(0);
        pathname === ROUTES.ACCOUNT_SELECTION && setCurrentValue(25);
        pathname === ROUTES.ONBOARDING_REQUESTS && setCurrentValue(50);
        pathname === ROUTES.ONBOARDING_FORMS && setCurrentValue(75);
        (pathname === ROUTES.ENVELOPES_VALIDATION || pathname === ROUTES.ONBOARDING_STATUS) &&
            setCurrentValue(100);
    }, [pathname, userOverallStatus]);
    const trackNavigationLinkClick = (href, linkLabel) => {
        trackDataLayerEvent({
            event: 'mainNavigationClick',
            contentUpdateDate: null,
            corporateScope: AUTH_CHANNEL_MAPPER_REVERSED[AUTH_CHANNEL],
            eventContext: 'Digital Product Portal',
            language: language,
            navigationLabel: linkLabel,
            navigationElement: 'navigation_level_1',
            pageId: pathname,
            pageLayout: 'desktop',
            pageReleaseDate: null,
            pageTemplate: null,
            pageTitle: pathnameToPageTitle(pathname),
            pageType: 'Antragsstrecke',
            pageURL: window.location.href,
            targetId: null,
            targetURL: href,
        });
    };
    if (ERRORED_ROUTES.includes(pathname) || !allowRegisterInterest) {
        return React.createElement(NavPlaceholder, null);
    }
    return (!allRequestsTriggeredInternally && (React.createElement(ApplicationNavigation, null,
        React.createElement(NavigationItemsWrapper, null,
            React.createElement(StyledLink, { to: ROUTES.LANDING_PAGE, onClick: (ev) => {
                    !canActivate.LANDING_PAGE
                        ? ev.preventDefault()
                        : trackNavigationLinkClick(window.location.origin + ROUTES.LANDING_PAGE, 'Home');
                } },
                React.createElement(HomeIcon, null)),
            React.createElement(StyledLink, { to: ROUTES.CONTACT_DETAILS, onClick: (ev) => {
                    !canActivate.CONTACT_DETAILS
                        ? ev.preventDefault()
                        : trackNavigationLinkClick(window.location.origin + ROUTES.CONTACT_DETAILS, formatMessage(globalMessages.contactDetails));
                } }, formatMessage(globalMessages.contactDetails)),
            userNotSupported && (React.createElement(StyledLink, { to: ROUTES.TOURIST_USERS, onClick: (ev) => {
                    !canActivate.TOURIST_USERS
                        ? ev.preventDefault()
                        : trackNavigationLinkClick(window.location.origin + ROUTES.TOURIST_USERS, formatMessage(touristUsersMessages.registerInterest));
                } }, formatMessage(touristUsersMessages.registerInterest))),
            !userNotSupported && (React.createElement(React.Fragment, null,
                React.createElement(StyledLink, { to: ROUTES.ACCOUNT_SELECTION, onClick: (ev) => {
                        navDisabled || !canActivate.ACCOUNT_SELECTION
                            ? ev.preventDefault()
                            : trackNavigationLinkClick(window.location.origin + ROUTES.ACCOUNT_SELECTION, formatMessage(globalMessages.accountSelection));
                    } }, formatMessage(globalMessages.accountSelection)),
                React.createElement(StyledLink, { to: ROUTES.ONBOARDING_REQUESTS, onClick: (ev) => {
                        navDisabled || !canActivate.ONBOARDING_REQUESTS
                            ? ev.preventDefault()
                            : trackNavigationLinkClick(window.location.origin + ROUTES.ONBOARDING_REQUESTS, formatMessage(globalMessages.onboardingRequests));
                    } }, formatMessage(globalMessages.onboardingRequests)),
                React.createElement(StyledLink, { to: ROUTES.ONBOARDING_FORMS, onClick: (ev) => {
                        navDisabled || !canActivate.ONBOARDING_FORMS
                            ? ev.preventDefault()
                            : trackNavigationLinkClick(window.location.origin + ROUTES.ONBOARDING_FORMS, formatMessage(globalMessages.onboardingForms));
                    } }, formatMessage(globalMessages.onboardingForms)),
                canActivate.ONBOARDING_STATUS && (React.createElement(StyledLink, { to: ROUTES.ONBOARDING_STATUS, onClick: (ev) => {
                        navDisabled || !canActivate.ONBOARDING_STATUS
                            ? ev.preventDefault()
                            : trackNavigationLinkClick(window.location.origin + ROUTES.ONBOARDING_STATUS, formatMessage(globalMessages.onboardingStatus));
                    } }, formatMessage(globalMessages.onboardingStatus)))))),
        React.createElement(ProgressBar, { isVisible: !userNotSupported && pathname !== ROUTES.LANDING_PAGE, currentValue: currentValue, message: pathname === ROUTES.ONBOARDING_STATUS &&
                userOverallStatus === OVERALL_STATUS.LEGAL_COMPLETION
                ? onboardingRequestsMessages.envelopesSigningProgress
                : onboardingRequestsMessages.onboardingApplicationProgress, width: 295 }))));
};
export default Navigation;
