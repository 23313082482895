import { startCase } from 'lodash';
export const pathnameToPageTitle = (pathname) => {
    var _a;
    const pageTitle = (_a = startCase(pathname.replace(/[\\]/g, ''))) !== null && _a !== void 0 ? _a : 'Home';
    return `DPP ${pageTitle} Page`;
};
export const trackDataLayerEvent = (params) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(params);
};
