import { NavLink as Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { NAV_HEIGHT } from 'constants/htmlElements';
export const NavigationItemsWrapper = styled.div `
	display: flex;
	align-items: center;
`;
export const NavPlaceholder = styled.div `
	height: 25px;
`;
export const ApplicationNavigation = styled.nav `
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: ${NAV_HEIGHT};
	padding: 0 17px 0 12px;
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.36);
	position: relative;
`;
export const StyledLink = styled(Link) `
	display: flex;
	align-items: center;
	min-height: 60px;
	cursor: pointer;
	margin-right: 25px;
	font-weight: 700;
	color: unset;
	opacity: 0.2;
	border-bottom: 5px solid transparent;

	&.active {
		opacity: 1;
		border-color: ${({ theme }) => theme.colors.yellow};
	}
`;
