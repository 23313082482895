import { breakpoints } from './breakpoints';
import colors from './colors';
import { durations } from './durations';
import { fontSizes } from './font-sizes';
import layout from './layout';
import misc from './misc';
import { modalSizes } from './modal';
import spacing from './spacing';
import transition from './transition';
import typography from './typography';
import zIndex from './z-index';
export const theme = Object.assign({ breakpoints,
    typography,
    colors,
    layout,
    spacing,
    transition,
    durations,
    fontSizes,
    zIndex,
    modalSizes }, misc);
