var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { useGetUserQuery, useKeepAliveTriggerQuery, useLoginMutation, useMockLoginMutation, } from 'api';
import { Loader } from 'components';
import { IS_LOCALHOST } from 'constants/environments';
import { ERRORED_ROUTES, ROUTES } from 'constants/routes';
import { LOCALES } from 'i18n/locales';
import { authSelector, configSelector } from 'store/reducers';
import { getSessionDuration } from 'utils/common';
import { AUTH_CHANNEL_MAPPER } from 'utils/mappers';
export const AccessProvider = ({ children }) => {
    const { pathname } = useLocation();
    const { decodedAuthTokenNameId = '', sessionValid, loginServerFailure, userServerFailure, useMockLogin, } = useSelector(authSelector);
    const { AUTH_CHANNEL, LANG_COOKIE, LOGIN_COOKIE_TOKEN } = useSelector(configSelector);
    const [login] = useLoginMutation();
    const [mockLogin] = useMockLoginMutation();
    const { isLoading, isUninitialized } = useGetUserQuery(decodedAuthTokenNameId, {
        skip: !decodedAuthTokenNameId,
    });
    useKeepAliveTriggerQuery(undefined, {
        pollingInterval: getSessionDuration(IS_LOCALHOST),
    });
    useEffect(() => {
        function executeLogin() {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b;
                try {
                    const mockLoginResponse = useMockLogin
                        ? yield mockLogin({
                            tnvekenn: (_a = process.env.MOCKED_TNVEKENN) !== null && _a !== void 0 ? _a : '',
                            bpkenn: (_b = process.env.MOCKED_BPKENN) !== null && _b !== void 0 ? _b : '',
                            lifeTime: 157680000, // @TODO check
                            channel: AUTH_CHANNEL_MAPPER.PUK,
                            language: LOCALES.ENGLISH,
                        }).unwrap()
                        : null;
                    login({
                        token: useMockLogin && mockLoginResponse ? mockLoginResponse.token : LOGIN_COOKIE_TOKEN,
                        userAgent: navigator.userAgent,
                        channel: AUTH_CHANNEL,
                        language: LANG_COOKIE,
                    });
                }
                catch (err) {
                    console.log(err);
                }
            });
        }
        executeLogin();
    }, []);
    if (loginServerFailure && pathname !== ROUTES.LOGIN_FAILED) {
        return React.createElement(Navigate, { to: ROUTES.LOGIN_FAILED });
    }
    if (userServerFailure && pathname !== ROUTES.USER_FAILED) {
        return React.createElement(Navigate, { to: ROUTES.USER_FAILED });
    }
    if (!sessionValid && !ERRORED_ROUTES.includes(pathname)) {
        return React.createElement(Navigate, { to: ROUTES.LOGIN_PORTALS });
    }
    if (sessionValid && (isUninitialized || isLoading)) {
        return React.createElement(Loader, null);
    }
    return React.createElement(React.Fragment, null, children);
};
