import styled, { css } from 'styled-components';
import { timing } from 'assets/styles/animations';
export const ToggleSwitchWrapper = styled.div `
	position: relative;
	display: flex;
	justify-content: space-around;
	align-items: center;
	z-index: 0;
	background: ${({ theme }) => theme.colors.secondaryWhite};
	border-radius: 20px;
	padding: 8px 0;
	cursor: pointer;
	user-select: none;
	white-space: nowrap;

	${({ disabled }) => disabled &&
    css `
	pointer-events: none;
	cursor: not-allowed;
	opacity: 0.5;
	`}

	&::after {
		content: '';
		width: ${({ leftToggleWidth = 0, secondarySelected }) => secondarySelected ? `calc(100% - ${leftToggleWidth}px)` : `${leftToggleWidth}px`};
		position: absolute;
		top: 0;
		bottom: 0;
		left: ${({ leftToggleWidth = 0, secondarySelected }) => secondarySelected ? `${leftToggleWidth}px` : '0'};

		background: ${({ theme }) => theme.colors.yellow};
		z-index: -1;
		border-radius: 20px;
		transition: all ${timing()};
	}
`;
export const ToggleSwitchItem = styled.div `
	flex: 1;
	text-align: center;
	padding: 0 16px;
	font-size: 14px;
	font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
`;
