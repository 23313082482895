import { createSlice } from '@reduxjs/toolkit';
import Bowser from 'bowser';
import { SUPPORTED_BROWSERS_NAMES } from 'constants/index';
const initialState = {
    isSupported: (() => {
        var _a, _b, _c;
        const browserName = (_c = (_b = (_a = Bowser.parse(window.navigator.userAgent).browser) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.toLowerCase()) !== null && _c !== void 0 ? _c : '';
        return SUPPORTED_BROWSERS_NAMES.includes(browserName);
    })(),
    isFocused: true,
    isOnline: true,
    inactiveSince: null,
    redirectTo: null,
    history: [],
};
export const browserSlice = createSlice({
    name: 'browser',
    initialState,
    reducers: {
        setInactiveSince: (state, action) => {
            state.inactiveSince = action.payload;
        },
        setRedirectTo: (state, action) => {
            state.redirectTo = action.payload;
        },
        setNewHistoryItem: (state, action) => {
            state.history.push(action.payload);
        },
    },
});
export const { setInactiveSince, setRedirectTo, setNewHistoryItem } = browserSlice.actions;
export default browserSlice.reducer;
export const browserSelector = ({ browser }) => browser;
