import { styled } from 'styled-components';
import { FOOTER_HEIGHT } from 'constants/htmlElements';
export const StyledFooter = styled.footer `
	position: sticky;
	bottom: 0;
	display: flex;
	height: ${FOOTER_HEIGHT};
	margin-top: auto;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.colors.lightPetrol};
	padding: ${({ theme }) => `${theme.spacing(1.25)} ${theme.spacing(1.8)}`};
	color: ${({ theme }) => theme.colors.white};
	font-size: ${({ theme }) => theme.fontSizes[13]};
`;
export const StyledLinksWrapper = styled.div `
	display: flex;
`;
export const StyledFooterText = styled.p ``;
export const StyledFooterLink = styled.a `
	position: relative;
	text-decoration: none;
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;

	&:not(:first-of-type) {
		margin-left: 15px;
	}

	&:hover {
		&::after {
			content: '';
			position: absolute;
			bottom: -2px;
			left: 0;
			width: 100%;
			border-bottom: 2px solid ${({ theme }) => theme.colors.yellow};
		}
	}
`;
