import { styled } from 'styled-components';
export const TextAreaWrapper = styled.div `
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: ${({ minHeight = '35vh' }) => minHeight};
	margin-bottom: ${({ theme }) => theme.spacing(1)};
`;
export const StyledTextArea = styled.textarea `
	width: 100%;
	resize: none;
	border: 2px solid ${({ theme }) => theme.colors.grey};
	flex: 1;
`;
export const StyledDescriptionMessage = styled.p `
	font-style: italic;
	font-size: ${({ theme }) => theme.fontSizes[12]};
`;
