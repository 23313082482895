import { styled } from 'styled-components';
import { HEADER_HEIGHT } from 'constants/htmlElements';
export const ApplicationHeader = styled.header `
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: ${HEADER_HEIGHT};
	padding: 13px 17px;
	background-color: ${({ theme }) => theme.colors.lightPetrol};
`;
export const Container = styled.div `
	display: flex;
	gap: ${({ theme }) => theme.spacing(2)};
	align-items: center;
	font-weight: 400;
`;
export const Logo = styled.img `
	width: 166px;
	height: 22px;
	margin-right: 24px;
	cursor: pointer;
`;
export const Text = styled.p `
	color: ${({ theme }) => theme.colors.white};
`;
export const SmallText = styled.p `
	color: ${({ theme }) => theme.colors.grey};
	font-size: 0.8rem;
`;
export const Pipe = styled.span `
	padding: 0 17px;
	color: ${({ theme }) => theme.colors.darkGrey};
	font-size: 1.2rem;
`;
