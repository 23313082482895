import { css } from 'styled-components';
import { theme } from 'assets/styles/theme';
export function scrollable(direction = 'y', backgroundColor, scrollbarBackgroundColor = theme.colors.lightPetrol) {
    const directionProperty = direction === 'x' ? 'overflow-x' : 'overflow-y';
    const bgr = backgroundColor || theme.colors.lightGrey;
    return css `
		${directionProperty}: auto;
		-webkit-overflow-scrolling: touch;

		scrollbar-color: ${scrollbarBackgroundColor} ${bgr};
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}

		&::-webkit-scrollbar-thumb {
			background: ${scrollbarBackgroundColor};
		}

		&::-webkit-scrollbar-track {
			background: ${bgr};
		}
	`;
}
