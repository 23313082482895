import { ReactComponent as ChromeIcon } from 'assets/icons/chrome.svg';
import { ReactComponent as ChromiumIcon } from 'assets/icons/chromium.svg';
import { ReactComponent as EdgeIcon } from 'assets/icons/edge.svg';
import { ReactComponent as FirefoxIcon } from 'assets/icons/firefox.svg';
import { ReactComponent as OperaIcon } from 'assets/icons/opera.svg';
import { ReactComponent as SafariIcon } from 'assets/icons/safari.svg';
export const SUPPORTED_BROWSERS = [
    { name: 'microsoft edge', downloadLink: 'https://www.microsoft.com/edge', icon: EdgeIcon },
    { name: 'chrome', downloadLink: 'https://www.google.com/chrome/', icon: ChromeIcon },
    {
        name: 'chromium',
        downloadLink: 'https://www.chromium.org/getting-involved/download-chromium/',
        icon: ChromiumIcon,
    },
    { name: 'firefox', downloadLink: 'https://www.mozilla.org/firefox/new/', icon: FirefoxIcon },
    { name: 'opera', downloadLink: 'https://www.opera.com/download', icon: OperaIcon },
    { name: 'safari', downloadLink: 'https://www.apple.com/safari/', icon: SafariIcon },
];
export const SUPPORTED_BROWSERS_NAMES = SUPPORTED_BROWSERS.map((browser) => browser.name);
