import React, { useEffect, useState } from 'react';
import { ReactComponent as Checked } from 'assets/icons/check.svg';
import { DecorativeCheckbox } from 'shared/styled/Inputs';
import { CheckboxLabel, InputCheckbox } from './Checkbox.styles';
import CheckboxFormik from './FormikCheckbox';
export const BasicCheckbox = ({ label, name, checked, onToggle, hasError, disabled, }) => {
    const [isChecked, setIsChecked] = useState(Boolean(checked));
    useEffect(() => {
        setIsChecked(Boolean(checked));
    }, [checked]);
    const toggle = () => {
        setIsChecked(!isChecked);
        onToggle === null || onToggle === void 0 ? void 0 : onToggle(!isChecked);
    };
    return (React.createElement(CheckboxLabel, { checked: isChecked, hasError: hasError, title: label },
        React.createElement(InputCheckbox, { type: "checkbox", onChange: !disabled ? toggle : undefined, name: name }),
        React.createElement(DecorativeCheckbox, { checked: isChecked, hasError: hasError, "aria-hidden": "true" }, isChecked && React.createElement(Checked, null)),
        label));
};
const Checkbox = Object.assign(BasicCheckbox, { Formik: CheckboxFormik });
export default Checkbox;
