import styled, { css } from 'styled-components';
import { pxToRem } from 'assets/styles/utils';
export const StyledButton = styled.button `
	display: ${({ hidden }) => (hidden ? 'none' : 'inline-flex')};
	align-items: center;
	justify-content: center;
	width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
	height: auto;
	padding: ${({ small, theme }) => small
    ? `${theme.spacing(0.5)} ${theme.spacing(1.5)}`
    : `${theme.spacing(1)} ${theme.spacing(3)}`};
	font-size: ${({ small, theme }) => (small ? theme.fontSizes[11] : theme.fontSizes[13])};
	font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
	border-radius: 100vh;
	border-width: 1px;
	line-height: 17px;
	border-style: solid;
	transition: ${({ theme }) => theme.transition('all', 'fast')};
	background-color: ${({ variant, theme }) => variant === 'primary' ? theme.colors.yellow : 'transparent'};
	border-color: ${({ variant, theme }) => variant === 'primary' ? theme.colors.yellow : theme.colors.lightPetrol};
	outline: none;
	white-space: ${({ isOverflown }) => (isOverflown ? 'initial' : 'nowrap')};
	word-wrap: break-word;

	> svg {
		margin-right: ${({ theme }) => theme.spacing(1)};
		display: block;
	}

	&:hover,
	&:focus {
		&:not(:disabled) {
			background-color: ${({ variant, theme }) => variant === 'primary' && theme.colors.orange};
			border-color: ${({ theme }) => theme.colors.orange};
		}
	}

	&:active:not(:disabled) {
		background-color: ${({ variant, theme }) => variant === 'primary' && theme.colors.yellow};
		border-color: ${({ theme }) => theme.colors.yellow};
	}

	&:disabled {
		opacity: ${({ theme }) => theme.opacity[30]};
		cursor: default;
	}

	${({ variant, theme }) => variant === 'nude' &&
    css `
			background: none;
			padding: 5px;
			border: 0;
			justify-content: flex-end;

			svg {
				margin: 0;
			}

			&:hover:not(:disabled),
			&:focus:not(:disabled) {
				background: ${theme.colors.hoverYellow};
				border: none;
				svg {
					color: ${theme.colors.lightPetrol};
				}
			}
		`}
`;
export const PipsBpsButton = styled.button `
	display: inline-flex;
	padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(1)};
	font-size: ${({ theme }) => theme.fontSizes[11]};
	font-weight: ${({ theme }) => theme.typography.fontWeights.light};
	background-color: ${({ theme, active }) => active ? theme.colors.yellow : theme.colors.bluePrimary};
	border-radius: 5px;
	margin: 0 ${({ theme }) => theme.spacing(0.5)};
`;
export const AddButton = styled.button `
	margin-top: ${({ theme }) => theme.spacing(1)};
	background-color: rgba(255, 255, 255, 0.5);
	width: 100%;
	height: ${pxToRem(50)};
	color: ${({ theme }) => theme.colors.darkGrey};
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: ${({ theme }) => theme.colors.yellow};
	}
`;
