import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import translations from 'i18n/translations';
import { userSelector } from 'store';
export const I18nProvider = ({ children }) => {
    const { language } = useSelector(userSelector);
    return (React.createElement(IntlProvider, { locale: language, messages: translations[language] }, children));
};
export default I18nProvider;
