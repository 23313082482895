import { globalMessages } from 'i18n/messages';
export const footerLinks = [
    {
        text: globalMessages.terms,
        href: 'https://www.firmenkunden.commerzbank.de/portal/de/cb/de/footer/agb/home.html',
    },
    {
        text: globalMessages.security,
        href: 'https://www.firmenkunden.commerzbank.de/portal/de/cb/de/footer/sicherheit/home.html',
    },
    {
        text: globalMessages.imprint,
        href: 'https://www.firmenkunden.commerzbank.de/portal/de/cb/de/footer/impressum/home.html',
    },
    {
        text: globalMessages.legalNotices,
        href: 'https://www.firmenkunden.commerzbank.de/portal/de/cb/de/footer/recht/home.html',
    },
    {
        text: globalMessages.regulatoryDisclosures,
        href: 'https://www.firmenkunden.commerzbank.de/portal/de/cb/de/footer/regulatory-disclosures/regulatory_disclosures_1.html',
    },
];
