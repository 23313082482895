import { defineMessages } from 'react-intl';
export const touristUsersMessages = defineMessages({
    welcomeHeadingMessage: {
        id: 'TouristUsers.welcomeHeadingMessage',
        defaultMessage: 'Self Onboarding Coming Soon!',
    },
    welcomeSubHeadingMessage: {
        id: 'TouristUsers.welcomeSubHeadingMessage',
        defaultMessage: "In the meantime register your interest and we'll be in touch.",
    },
    registerInterest: {
        id: 'TouristUsers.registerInterest',
        defaultMessage: 'Register interest',
    },
    registerUserInterestSuccess: {
        id: 'TouristUsers.registerUserInterestSuccess',
        defaultMessage: 'Successfully registered your interest.',
    },
    touristPageIntroText: {
        id: 'TouristUsers.touristPageIntroText',
        defaultMessage: "Please answer the following questions, don't worry if you're not entirely sure but it will give us an idea of how we can help.",
    },
    addCompany: {
        id: 'TouristUsers.addCompany',
        defaultMessage: 'Add Company',
    },
    whichCompanies: {
        id: 'TouristUsers.whichCompanies',
        defaultMessage: 'Which company(ies) are you looking to trade for?',
    },
    whatTypeOfTransaction: {
        id: 'TouristUsers.whatTypeOfTransaction',
        defaultMessage: 'What type of transaction will you make?',
    },
    whichCurrencies: {
        id: 'TouristUsers.whichCurrencies',
        defaultMessage: 'Which currencies are you interested in trading?',
    },
});
