// ========================================================================
// THEME/MISC
// ========================================================================
export default {
    borderRadius: '3px',
    boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.25)',
    opacity: {
        20: 0.2,
        30: 0.3,
        70: 0.7,
        80: 0.8,
        100: 1,
    },
};
