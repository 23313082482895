import { styled, css } from 'styled-components';
import { FOOTER_HEIGHT, HEADER_HEIGHT, NAV_HEIGHT } from 'constants/index';
import { scrollable } from 'utils/scrollable';
export const LayoutContainer = styled.div `
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`;
export const PageWrapper = styled.div `
	position: relative;
	height: ${({ navHeight, limitHeightToViewport = false }) => {
    const NAV_HEIGHT_SECTION = (typeof navHeight === 'number' ? `${navHeight}px` : navHeight) || NAV_HEIGHT;
    return limitHeightToViewport
        ? `calc(100vh - ${HEADER_HEIGHT} - ${NAV_HEIGHT_SECTION} - ${FOOTER_HEIGHT})`
        : 'auto';
}};

	width: 100%;

	${({ variant = 'normal' }) => {
    switch (variant) {
        case 'normal':
            return css `
					display: flex;
				`;
        case 'split':
            return css `
					display: grid;
					grid-template-columns: 1fr 1fr;
					text-align: center;
				`;
        default:
            break;
    }
}};
`;
export const OverlayWrapper = styled.div `
	display: block;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 100vh;
	width: 100vw;
	z-index: 102;
`;
export const SectionInformation = styled.section `
	height: 100%;
	padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(7)} ${theme.spacing(3.5)}`};
	border-bottom: ${({ withBorder = true, theme }) => withBorder ? `1px solid ${theme.colors.scalesLight}` : 'none'};
	color: ${({ theme }) => theme.colors.lightPetrol};

	&:last-of-type {
		padding-bottom: ${({ theme }) => theme.spacing(13)};
	}

	${({ hidden }) => hidden &&
    css `
			display: none;
		`}

	${({ disabled }) => disabled &&
    css `
			opacity: 0.5;
			cursor: not-allowed;
		`};

	@media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}) {
		padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(0)} ${theme.spacing(3.5)}`};
	}
`;
export const ScrollableContainer = styled.div `
	overflow-y: auto;
	width: ${({ width = 'auto' }) => width};
	height: 100%;
	padding: ${({ padding = 0 }) => padding};
	max-height: ${({ maxHeight = '100%' }) => maxHeight};
	${({ direction, backgroundColor, scrollbarBackgroundColor }) => scrollable(direction, backgroundColor, scrollbarBackgroundColor)};
`;
export const StyledError = styled.span `
	position: absolute;
	display: block;
	line-height: 1;
	white-space: pre;
	bottom: ${({ theme }) => theme.spacing(-2)};
	color: ${({ theme }) => theme.colors.red};
	font-size: ${({ theme }) => theme.fontSizes[11]};
	font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
	margin-right: ${({ theme }) => theme.spacing(1)};
	margin-top: ${({ theme }) => theme.spacing(0.5)};
`;
export const ButtonsSection = styled.div `
	display: grid;
	grid-template-columns: ${({ columns = 2 }) => `repeat(${columns}, 1fr)`};
	align-items: ${({ alignItems = 'normal' }) => alignItems};
	margin: auto;
	column-gap: 12px;
	padding: 1.5rem 1rem;
	background-color: ${({ theme }) => theme.colors.white};
	text-align: center;
`;
export const FlexContainer = styled.div `
	display: flex;
	flex: ${({ flex = 'auto' }) => flex};
	position: ${({ position = 'relative' }) => position};
	align-items: ${({ alignItems = 'center' }) => alignItems};
	align-self: ${({ alignSelf = 'initial' }) => alignSelf};
	flex-direction: ${({ direction = 'row' }) => direction};
	justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
	height: ${({ height = 'auto' }) => height};
	width: ${({ width = 'auto' }) => width};
	max-width: ${({ maxWidth = 'auto' }) => maxWidth};
	margin: ${({ margin = 'unset' }) => margin};
	padding: ${({ padding = 'unset' }) => padding};
	gap: ${({ gap = 'unset' }) => gap};
`;
export const ExpandedAbsoluteContainer = styled.div `
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;
export const CenteredAbsoluteContainer = styled.div `
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;
export const ButtonsWrapper = styled.div `
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	gap: ${({ theme }) => theme.spacing(1.5)};
	margin: ${({ marginTop = '0px', marginBottom = '0px', marginRight = '0px', marginLeft = '0px', }) => `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`};
`;
export const OrdinaryDiv = styled.div ``;
export const Message = styled.div `
	font-size: ${({ theme }) => theme.fontSizes[18]};
	font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
	text-align: ${({ textAlign = 'unset' }) => textAlign};
	padding: ${({ padding = 0 }) => padding};
`;
export const Paragraph = styled.p `
	display: ${({ display = 'block' }) => display};
	width: ${({ width = 'auto' }) => width};
	height: ${({ height = 'auto' }) => height};
	align-items: ${({ alignItems = 'initial' }) => alignItems};
	color: ${({ theme, color }) => color || theme.colors.lightPetrol};
	padding: ${({ padding = 0 }) => padding};
	font-size: ${({ fontSize = 'inherit' }) => fontSize};
	font-style: ${({ fontStyle = 'inherit' }) => fontStyle};
	font-weight: ${({ bolded = false }) => (bolded ? '700' : 'initial')};
	text-align: ${({ textAlign = 'inherit' }) => textAlign};
`;
export const StyledDescriptionMessage = styled.p `
	font-style: italic;
	font-size: ${({ theme }) => theme.fontSizes[12]};
`;
export const Title = styled.p `
	margin-top: ${({ theme }) => theme.spacing(2.5)};
	color: ${({ theme }) => theme.colors.darkGrey};
	font-size: ${({ fontSize }) => fontSize || 'inherit'};
	font-weight: ${({ theme, fontWeight }) => fontWeight || theme.typography.fontWeights.medium};
	text-align: ${({ textAlign = 'initial' }) => textAlign};
`;
export const AddNew = styled.p `
	display: flex;
	align-items: center;
	cursor: pointer;
	white-space: nowrap;
	color: ${({ theme, color, hasError }) => hasError ? theme.colors.red : color !== null && color !== void 0 ? color : theme.colors.darkGrey};
	font-size: ${({ theme }) => theme.fontSizes[14]};
	font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
`;
