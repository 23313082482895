import { BANK_METHODS_CONFIRMATION, ONBOARDING_PRODUCTS, TRADING_PRODUCTS } from 'utils/mappers';
export const EMPTY_SETTLEMENT_INSTRUCTION_ACCOUNT = {
    currency: '',
    account: '',
    locked: false,
    preferredIncoming: undefined,
    preferredOutgoing: undefined,
};
export const DEFAULT_TRADING_INSTRUCTIONS = {
    tradingProducts: TRADING_PRODUCTS.UNKNOWN,
    tradingProductLocked: false,
    tradingProductHidden: false,
    settlementLimit: {
        id: 0,
        name: {
            en: '',
            de: '',
        },
        complex: false,
    },
    settlementLimitLocked: false,
    settlementLimitHidden: false,
    settlementInstructions: [EMPTY_SETTLEMENT_INSTRUCTION_ACCOUNT],
    settlementInstructionsLocked: false,
    settlementInstructionsHidden: false,
};
export const DEFAULT_CONFIRMATION_INSTRUCTIONS = {
    method: BANK_METHODS_CONFIRMATION.UNKNOWN,
    the4EyeCheck: false,
    the4EyeCheckLocked: false,
    the4EyeCheckHidden: false,
    methodLocked: false,
    methodHidden: false,
};
export const onboardingRequestsInitialState = {
    id: '',
    counterpartyId: '',
    legalName: '',
    products: ONBOARDING_PRODUCTS.UNKNOWN,
    availableProducts: [],
    tradingInstructions: DEFAULT_TRADING_INSTRUCTIONS,
    confirmationInstructions: DEFAULT_CONFIRMATION_INSTRUCTIONS,
    status: 0,
    isValidated: false,
    isSelected: false,
    triggeredInternally: false,
    isTouristSquared: false,
};
export const reinitializedOnboardingRequestsState = (selectedRequest) => {
    var _a, _b;
    if (!selectedRequest) {
        return onboardingRequestsInitialState;
    }
    const { id = '', counterpartyId = '', legalName = '', products = null, availableProducts, tradingInstructions, confirmationInstructions, status = 0, isValidated = false, isSelected = false, triggeredInternally = false, isTouristSquared, } = selectedRequest || {};
    const { tradingProducts, tradingProductLocked, tradingProductHidden, settlementLimit, settlementLimitHidden, settlementLimitLocked, settlementInstructionsLocked, settlementInstructionsHidden, } = tradingInstructions || DEFAULT_TRADING_INSTRUCTIONS;
    const { method, methodLocked, methodHidden, the4EyeCheck, the4EyeCheckLocked, the4EyeCheckHidden, } = confirmationInstructions || DEFAULT_CONFIRMATION_INSTRUCTIONS;
    return {
        id,
        counterpartyId,
        legalName,
        products: products !== null && products !== void 0 ? products : ONBOARDING_PRODUCTS.UNKNOWN,
        availableProducts,
        tradingInstructions: {
            tradingProducts: tradingProducts || TRADING_PRODUCTS.SPOT,
            tradingProductLocked,
            tradingProductHidden,
            settlementLimit,
            settlementLimitHidden,
            settlementLimitLocked,
            settlementInstructionsLocked,
            settlementInstructionsHidden,
            settlementInstructions: settlementInstructionsLocked
                ? []
                : ((_b = (_a = tradingInstructions === null || tradingInstructions === void 0 ? void 0 : tradingInstructions.settlementInstructions) === null || _a === void 0 ? void 0 : _a.map((setllementInstruction) => ({
                    currency: setllementInstruction.currency,
                    account: setllementInstruction.account,
                    locked: setllementInstruction.locked,
                    preferredIncoming: setllementInstruction.preferredIncoming,
                    preferredOutgoing: setllementInstruction.preferredOutgoing,
                }))) !== null && _b !== void 0 ? _b : []),
        },
        confirmationInstructions: {
            method: method,
            methodLocked: methodLocked,
            methodHidden: methodHidden,
            the4EyeCheck: the4EyeCheck,
            the4EyeCheckLocked: the4EyeCheckLocked,
            the4EyeCheckHidden: the4EyeCheckHidden,
        },
        status,
        isValidated,
        isSelected,
        triggeredInternally,
        isTouristSquared,
    };
};
export const reportProblemFormInitialValues = {
    details: '',
};
export const contactUsFormInitialValues = {
    screenShot: '',
    details: '',
    viaEmail: true,
    viaPhone: false,
    email: '',
    phone: '',
};
export const registerUserInterestFormInitialValues = {
    company: [],
    currency: [],
    isSpot: true,
    isForward: false,
    viaEmail: false,
    viaPhone: false,
};
