import { createGlobalStyle } from 'styled-components';
import 'assets/styles/global/base/fonts.css';
import { boxSizing, wordWrap, normalize, reset } from './base';
import { datetime, images, links, page, tables } from './elements';
const GlobalStyles = createGlobalStyle `
  ${boxSizing};
  ${wordWrap};
  ${normalize};
  ${reset};
  ${page};
  ${links};
  ${images};
  ${tables};
  ${datetime};


  body {
    margin: unset;
    overflow-x: auto;
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  }

  #no-javascript-message { // needed due to bug in html-to-image library which displays the noscript tag even if javascript is enabled in user browser
    display: none;
  }

  body,
  button,
  input {
    color: ${({ theme }) => theme.colors.lightPetrol};
    font-weight: ${({ theme }) => theme.typography.fontWeights.regular};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
export default GlobalStyles;
