import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { LANGUAGE_COOKIE_NAME, LOGIN_CHANNEL_NAME, LOGIN_TOKEN_NAME, LOGIN_URL_NAME, } from 'constants/api';
import { IS_LOCALHOST } from 'constants/environments';
import { AUTH_CHANNEL_MAPPER } from 'utils/mappers';
const initialState = {
    AUTH_CHANNEL: (() => {
        const loginChannel = Number(Cookies.get(LOGIN_CHANNEL_NAME));
        if (!loginChannel) {
            console.error(`Missing ${LOGIN_CHANNEL_NAME} cookie!`);
            return AUTH_CHANNEL_MAPPER.UNKNOWN;
        }
        if (!Object.values(AUTH_CHANNEL_MAPPER).includes(loginChannel)) {
            console.error('Invalid auth channel value provided!');
            return AUTH_CHANNEL_MAPPER.UNKNOWN;
        }
        return loginChannel;
    })(),
    LOGIN_URL: (() => {
        const loginUrl = Cookies.get(LOGIN_URL_NAME);
        if (!loginUrl) {
            console.error(`Missing ${LOGIN_URL_NAME} cookie!`);
        }
        return loginUrl;
    })(),
    LANG_COOKIE: (() => {
        const langCookie = Cookies.get(LANGUAGE_COOKIE_NAME);
        if (!langCookie) {
            console.error(`Missing ${LANGUAGE_COOKIE_NAME} cookie!`);
        }
        return langCookie;
    })(),
    LOGIN_COOKIE_TOKEN: (() => {
        var _a;
        const loginCookieToken = (_a = Cookies.get(LOGIN_TOKEN_NAME)) !== null && _a !== void 0 ? _a : '';
        if (!loginCookieToken) {
            console.error(`Missing ${LOGIN_TOKEN_NAME} cookie!`);
        }
        return loginCookieToken;
    })(),
    ENABLE_GOOGLE_ANALYTICS: (() => {
        var _a;
        const enableGoogleAnalytics = Boolean(IS_LOCALHOST
            ? process.env.ENABLE_GOOGLE_ANALYTICS === 'true'
            : (_a = window === null || window === void 0 ? void 0 : window._env_) === null || _a === void 0 ? void 0 : _a.ENABLE_GOOGLE_ANALYTICS);
        return enableGoogleAnalytics;
    })(),
    BASE_WEBSOCKET_URL: (() => {
        var _a;
        const websocketUrl = IS_LOCALHOST
            ? process.env.BASE_WEBSOCKET_URL
            : (_a = window === null || window === void 0 ? void 0 : window._env_) === null || _a === void 0 ? void 0 : _a.BASE_WEBSOCKET_URL;
        if (!websocketUrl) {
            console.error('Missing BASE_WEBSOCKET_URL env variable!');
        }
        return websocketUrl !== null && websocketUrl !== void 0 ? websocketUrl : '';
    })(),
    GOOGLE_ANALYTICS_BASE_URL_FK: (() => {
        var _a;
        const googleAnalyticsFkClientsBaseUrl = IS_LOCALHOST
            ? process.env.GOOGLE_ANALYTICS_BASE_URL_FK
            : (_a = window === null || window === void 0 ? void 0 : window._env_) === null || _a === void 0 ? void 0 : _a.GOOGLE_ANALYTICS_BASE_URL_FK;
        if (!googleAnalyticsFkClientsBaseUrl) {
            console.error('Missing GOOGLE_ANALYTICS_BASE_URL_FK env variable!');
        }
        return googleAnalyticsFkClientsBaseUrl !== null && googleAnalyticsFkClientsBaseUrl !== void 0 ? googleAnalyticsFkClientsBaseUrl : '';
    })(),
    GOOGLE_ANALYTICS_BASE_URL_PK: (() => {
        var _a;
        const googleAnalyticsPkClientsBaseUrl = IS_LOCALHOST
            ? process.env.GOOGLE_ANALYTICS_BASE_URL_PK
            : (_a = window === null || window === void 0 ? void 0 : window._env_) === null || _a === void 0 ? void 0 : _a.GOOGLE_ANALYTICS_BASE_URL_PK;
        if (!googleAnalyticsPkClientsBaseUrl) {
            console.error('Missing GOOGLE_ANALYTICS_BASE_URL_PK env variable!');
        }
        return googleAnalyticsPkClientsBaseUrl !== null && googleAnalyticsPkClientsBaseUrl !== void 0 ? googleAnalyticsPkClientsBaseUrl : '';
    })(),
};
export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
});
export default configSlice.reducer;
export const configSelector = ({ config }) => config;
