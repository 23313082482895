import { DEFAULT_SESSION_DURATION, MINIMUM_SESSION_DURATION } from 'constants/common';
import { LOCALES } from 'i18n/locales';
export const createOption = (label) => ({
    label,
    value: label.toLowerCase().trim(),
    currentlyCreated: true,
});
export const getLanguage = (lang) => {
    var _a;
    return ((_a = lang === null || lang === void 0 ? void 0 : lang.startsWith) === null || _a === void 0 ? void 0 : _a.call(lang, LOCALES.GERMAN)) ? LOCALES.GERMAN : LOCALES.ENGLISH;
};
export const handleForward = (url) => {
    if (!url) {
        return;
    }
    window.location.href = url;
};
export const openUrlInNewTab = (url) => {
    var _a;
    if (!url) {
        return;
    }
    (_a = window.open(url, '_blank')) === null || _a === void 0 ? void 0 : _a.focus();
};
export const preventDefault = (event) => event.preventDefault();
export const getSessionDuration = (IS_LOCALHOST) => {
    var _a;
    const sessionDuration = Number(IS_LOCALHOST ? process.env.SESSION_DURATION : (_a = window === null || window === void 0 ? void 0 : window._env_) === null || _a === void 0 ? void 0 : _a.SESSION_DURATION);
    if (isNaN(sessionDuration)) {
        console.error('Provided value for interval timeframe is not a number! Returning fallback value.');
        return DEFAULT_SESSION_DURATION;
    }
    return Math.max(MINIMUM_SESSION_DURATION, sessionDuration); // prevents bombing the server with keep alive requests too often
};
