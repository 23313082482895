import React from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import { ReusableSvg } from 'components';
import { MODAL_ELEMENT_CLASSNAME, PORTAL_ID } from 'constants/htmlElements';
import { useModal } from 'hooks';
import { globalMessages } from 'i18n/messages';
import { FlexContainer } from 'shared/styled/Layout';
import { DefaultModalButtons } from 'views';
import { CloseIconWrapper, ModalContainer, ModalContent, ModalHeading, ModalHeadingWrapper, ModalWrapper, } from './Modal.styles';
export const Modal = ({ name, children, isOpen, isVisible = true, dismissOnClickOutside = true, dismissOnSubmit = true, closeModal = () => null, variant = 'small', displayDefaultActionButtons = true, displayXButton = true, closeButtonText = globalMessages.cancel, actionButtonText = globalMessages.continue, actionHandler = () => null, scrollableContent = true, htmlContent = false, minHeight = 'auto', height = '100%', padding, contentPadding, heading, }) => {
    var _a;
    const [handleClickOutsideOfModal] = useModal(isOpen, closeModal, dismissOnSubmit); // TODO consider extracting it out of Modal
    if (!isOpen) {
        return null;
    }
    return createPortal(React.createElement(ModalWrapper, { "data-name": name, className: MODAL_ELEMENT_CLASSNAME, isVisible: Boolean(isVisible), onClick: (event) => dismissOnClickOutside ? handleClickOutsideOfModal === null || handleClickOutsideOfModal === void 0 ? void 0 : handleClickOutsideOfModal(event) : () => null },
        React.createElement(ModalContainer, { variant: variant, padding: padding, minHeight: minHeight },
            React.createElement(ModalHeadingWrapper, null,
                React.createElement(FlexContainer, { justifyContent: "center" },
                    React.createElement(ModalHeading, null, heading),
                    displayXButton && (React.createElement(CloseIconWrapper, { onClick: closeModal, notAlignedWithHeading: !heading },
                        React.createElement(ReusableSvg, { Icon: CloseIcon, hoverable: true, width: "inherit" }))))),
            htmlContent ? (React.createElement(ModalContent, { scrollableContent: scrollableContent, dangerouslySetInnerHTML: { __html: (_a = children === null || children === void 0 ? void 0 : children.toString()) !== null && _a !== void 0 ? _a : '' }, height: height, padding: contentPadding, html //TODO: revisit later
                : true })) : (React.createElement(ModalContent, { scrollableContent: scrollableContent, height: height }, children)),
            displayDefaultActionButtons && (React.createElement(DefaultModalButtons, { actionButtonText: actionButtonText, closeButtonText: closeButtonText, actionHandler: actionHandler, closeModal: closeModal })))), document.getElementById(PORTAL_ID) || document.body);
};
export default Modal;
