import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';
import { api } from 'api/shared';
import { IS_DEVELOPMENT } from 'constants/environments';
import { browserMiddleware, errorMiddleware } from './middlewares';
import { authSlice, configSlice, browserSlice, formsStateSlice } from './reducers';
export const useAppDispatch = useDispatch;
export const reduxStore = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        auth: authSlice.reducer,
        config: configSlice.reducer,
        browser: browserSlice.reducer,
        formsState: formsStateSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, errorMiddleware, browserMiddleware),
    devTools: IS_DEVELOPMENT,
});
setupListeners(reduxStore.dispatch);
