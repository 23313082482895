import React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager, ThemeProvider as Theme } from 'styled-components';
import GlobalStyles from 'assets/styles/global';
import { theme } from 'assets/styles/theme';
const shouldForwardProp = (propName, target) => {
    // https://github.com/orgs/styled-components/discussions/4136 @TODO consider using $prefixes
    if (typeof target === 'string') {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
};
export const ThemeProvider = ({ children }) => (React.createElement(StyleSheetManager, { shouldForwardProp: shouldForwardProp },
    React.createElement(Theme, { theme: theme },
        React.createElement(GlobalStyles, null),
        children)));
export default ThemeProvider;
