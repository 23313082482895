import { styled } from 'styled-components';
export const OnboardingSectionInnerWrapper = styled.div `
	position: relative;
	max-width: ${({ maxWidth = '80%' }) => `${typeof maxWidth === 'number' ? maxWidth + 'px' : maxWidth}`};
	margin-top: ${({ marginTop = 0 }) => marginTop};
`;
export const WelcomeHeading = styled.h1 `
	font-size: 1.5rem;
	color: ${({ theme }) => theme.colors.grey};
	margin-bottom: 24px;
	font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
`;
export const WelcomeSubHeading = styled.h2 `
	font-size: 1.25rem;
	font-weight: 300;
	color: ${({ theme }) => theme.colors.lightGrey};
`;
export const EmailHeading = styled.h1 `
	font-size: 1.5rem;
	color: ${({ theme }) => theme.colors.lightGrey};
	margin: 40px 0;
	font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
`;
export const SectionParagraph = styled.p `
	width: 100%;
	font-size: 1rem;
	text-align: left;
	color: ${({ theme }) => theme.colors.grey};
	margin: 16px 0 ${({ extraBottomMargin = false }) => (extraBottomMargin ? '80px' : '25px')};
`;
export const DataProtectionInfoParagraph = styled.p `
	font-size: 0.8rem;
	text-align: left;
	color: ${({ theme }) => theme.colors.grey};
	margin-bottom: 25px;
`;
export const ButtonWrapper = styled.div `
	min-height: 48px;
	position: relative;
	position: relative;
	display: flex;
	flex-direction: row-reverse;

	button {
		width: 180px;
		border-color: ${({ variant, theme }) => variant === 'primary' ? theme.colors.yellow : theme.colors.darkGrey};
		color: ${({ theme }) => theme.colors.darkGrey};
		font-size: 18px;
		font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
	}
`;
export const Header = styled.header `
	height: 108px;
`;
