import { isRejectedWithValue, isRejected } from '@reduxjs/toolkit';
import { loginApiEndpoints } from 'api';
import { HTTP_STATUSES } from 'constants/http';
import { invalidateSession } from 'store/reducers';
import { getUserQueryResult } from 'store/selectors';
import { getLanguage } from 'utils/common';
import { pathnameToPageTitle, trackDataLayerEvent } from 'utils/googleAnalytics';
export const errorMiddleware = (api) => (next) => (action) => {
    var _a;
    if (isRejected(action)) {
        console.log(action);
        if (action.payload && typeof action.payload === 'object') {
            if ('status' in action.payload && action.payload.status === HTTP_STATUSES.UNAUTHORIZED) {
                api.dispatch(invalidateSession());
            }
        }
        if (action.meta.arg && typeof action.meta.arg === 'object') {
            if ('endpointName' in action.meta.arg &&
                action.meta.arg.endpointName === loginApiEndpoints.keepAlive.name) {
                const { AUTH_CHANNEL } = api.getState().config;
                const language = ((_a = getUserQueryResult(api.getState()).data) === null || _a === void 0 ? void 0 : _a.language) ||
                    getLanguage(navigator.language);
                trackDataLayerEvent({
                    event: 'logout',
                    clusterName: null,
                    corporateScope: AUTH_CHANNEL,
                    eventContext: 'Digital Product Portal',
                    language: language,
                    pageId: location.pathname,
                    pageLayout: 'desktop',
                    pageTemplate: null,
                    pageTitle: pathnameToPageTitle(location.pathname),
                    pageType: 'Antragsstrecke',
                    pageURL: location.pathname,
                    logoutMethod: 'inactive',
                    processName: 'FX Corporate Onboarding',
                });
            }
        }
    }
    if (isRejectedWithValue(action)) {
        console.error(action.error);
    }
    return next(action);
};
