import { ToastContainer } from 'react-toastify';
import { styled } from 'styled-components';
export const StyledToast = styled(ToastContainer) `
	width: 50vw;
	.Toastify__toast {
		background: ${({ theme }) => theme.colors.white};
		box-shadow: 3px 5px 9px -1px rgb(0 0 0 / 72%); // @TODO add boxShadow color in theme
		border-radius: 16px;
		border: 1px solid #e0e0dd;
		padding: 16px;
	}
`;
