var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { LAYER_ELEMENT_CLASSNAME, PORTAL_ID } from 'constants/index';
import { StyledLsgLayer } from './Layer.styles';
export const Layer = (_a) => {
    var { children, open, name, htmlAttrs } = _a, rest = __rest(_a, ["children", "open", "name", "htmlAttrs"]);
    const [isLayerOpen, setIsLayerOpen] = useState(false);
    useEffect(() => {
        setIsLayerOpen(Boolean(open)); // needed due to bug with inital opened state of Layer
    }, [open]);
    if (!open) {
        return null;
    }
    return createPortal(React.createElement(StyledLsgLayer, Object.assign(Object.assign({}, rest), { open: isLayerOpen, className: LAYER_ELEMENT_CLASSNAME, htmlAttrs: Object.assign(Object.assign({}, htmlAttrs), { 
            // @ts-ignore
            'data-name': name }) }), children), document.getElementById(PORTAL_ID) || document.body);
};
export default Layer;
