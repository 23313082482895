// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/fonts/Gotham-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/fonts/Gotham-Light.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("assets/fonts/Gotham-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("assets/fonts/Gotham-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("assets/fonts/Gotham-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("assets/fonts/Gotham-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("assets/fonts/Gotham-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("assets/fonts/Gotham-Bold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* used due to styled components re-render problem with fonts */

@font-face {
	font-family: 'Gotham';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
		url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),
		url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff');
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2'),
		url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff');
	font-weight: 600;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('woff2'),
		url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('woff');
	font-weight: 800;
	font-display: swap;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/global/base/fonts.css"],"names":[],"mappings":"AAAA,+DAA+D;;AAE/D;CACC,qBAAqB;CACrB;wDACqD;CACrD,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,qBAAqB;CACrB;wDACuD;CACvD,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,qBAAqB;CACrB;wDACsD;CACtD,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,qBAAqB;CACrB;wDACoD;CACpD,gBAAgB;CAChB,kBAAkB;AACnB","sourcesContent":["/* used due to styled components re-render problem with fonts */\r\n\r\n@font-face {\r\n\tfont-family: 'Gotham';\r\n\tsrc: url('assets/fonts/Gotham-Light.woff2') format('woff2'),\r\n\t\turl('assets/fonts/Gotham-Light.woff') format('woff');\r\n\tfont-weight: 300;\r\n\tfont-display: swap;\r\n}\r\n\r\n@font-face {\r\n\tfont-family: 'Gotham';\r\n\tsrc: url('assets/fonts/Gotham-Regular.woff2') format('woff2'),\r\n\t\turl('assets/fonts/Gotham-Regular.woff') format('woff');\r\n\tfont-weight: 400;\r\n\tfont-display: swap;\r\n}\r\n\r\n@font-face {\r\n\tfont-family: 'Gotham';\r\n\tsrc: url('assets/fonts/Gotham-Medium.woff2') format('woff2'),\r\n\t\turl('assets/fonts/Gotham-Medium.woff') format('woff');\r\n\tfont-weight: 600;\r\n\tfont-display: swap;\r\n}\r\n\r\n@font-face {\r\n\tfont-family: 'Gotham';\r\n\tsrc: url('assets/fonts/Gotham-Bold.woff2') format('woff2'),\r\n\t\turl('assets/fonts/Gotham-Bold.woff') format('woff');\r\n\tfont-weight: 800;\r\n\tfont-display: swap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
