import styled, { css } from 'styled-components';
export const StyledDualRing = styled.div `
	&::-webkit-scrollbar {
		display: none;
		width: 0 !important;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
	${({ variant = 'fixed' }) => variant === 'fixed' &&
    css `
			position: fixed;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			display: inline-block;
			width: 80px;
			height: 80px;
		`}

	&::after {
		content: ' ';
		display: block;
		width: 64px;
		height: 64px;
		margin: 8px;
		border-radius: 50%;
		border: 6px solid ${({ theme }) => theme.colors.yellow};
		border-color: ${({ theme }) => theme.colors.yellow} transparent
			${({ theme }) => theme.colors.yellow} transparent;
		animation: dual-ring 1.2s linear infinite;
	}

	@keyframes dual-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
