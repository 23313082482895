import { useState, useEffect, useRef } from 'react';
export const useFocus = () => {
    const ref = useRef(null);
    const [focused, setFocused] = useState(false);
    useEffect(() => {
        var _a;
        const activeElement = document.activeElement;
        setFocused(Boolean((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.contains(activeElement)));
    }, [document.activeElement]);
    return { ref, focused, setFocused };
};
