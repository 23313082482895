// ========================================================================
// GLOBAL/BASE/NORMALIZE
// ========================================================================
export const normalize = `
  h1,
  h2,
  h3,
  h4 {
    font-size: inherit;
    font-weight: inherit;
  }

  ol,
  ul {
    list-style: none;
  }

  small {
    font-size: 80%;
    line-height: normal;
  }

  strong {
    font-weight: bold;
  }

  fieldset {
    border: 0;
  }

  legend {
    color: inherit;
  }

  :not(input:not([type='checkbox']), textarea) {
    caret-color: transparent;
  }

  button,
  input,
  select,
  textarea {
    font: inherit;
    cursor: pointer;
    outline: none;
    caret-color: initial;
  }

  button {
    background: none;
    border: 0;
    padding: 0;
  }
`;
