import React from 'react';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import { onboardingRequestsMessages } from 'i18n/messages';
import { StyledError } from 'shared/styled';
import { StyledDescriptionMessage, StyledTextArea, TextAreaWrapper } from './TextArea.styles';
export const TextArea = ({ name, infoText, placeholder, minHeight, showError = true, }) => {
    const { formatMessage } = useIntl();
    const [field, { error }] = useField({
        name,
    });
    return (React.createElement(TextAreaWrapper, { minHeight: minHeight },
        infoText && React.createElement(StyledDescriptionMessage, null, infoText),
        React.createElement(StyledTextArea, Object.assign({}, field, { placeholder: placeholder })),
        showError && error && (React.createElement(StyledError, null, formatMessage(onboardingRequestsMessages.requiredField)))));
};
export default TextArea;
