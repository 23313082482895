import { styled } from 'styled-components';
export const SomethingWentWrongWrapper = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100vw;
	height: 100vh;
	padding: 10%;
	box-sizing: border-box;
	background: ${({ theme }) => theme.colors.white};
`;
