import { defineMessages } from 'react-intl';
export const contactDetailsMessages = defineMessages({
    enterContactDetails: {
        id: 'ContactDetails.enterContactDetails',
        defaultMessage: 'Please enter your contact details',
    },
    provideEmail: {
        id: 'ContactDetails.provideEmail',
        defaultMessage: 'Please provide telephone number in case of any queries and your email address to receive confirmation once onboarding is complete',
    },
    verifyYourEmail: {
        id: 'ContactDetails.verifyYourEmail',
        defaultMessage: 'Verify your email',
    },
    verifyYourEmailInfoText: {
        id: 'ContactDetails.verifyYourEmailInfoText',
        defaultMessage: "Please check your email address for the unique code we've just sent you and provide it below.",
    },
    verifyYourEmailExtraInfoText: {
        id: 'ContactDetails.verifyYourEmailExtraInfoText',
        defaultMessage: 'If the code has expired you may request a new one.',
    },
    newVerificationCodeRequestSuccess: {
        id: 'ContactDetails.newVerificationCodeRequestSuccess',
        defaultMessage: 'New verification code sent!',
    },
    newVerificationCodeRequestError: {
        id: 'ContactDetails.newVerificationCodeRequestError',
        defaultMessage: 'An error occured while sending a new verification code.',
    },
    emailVerifiedSuccess: {
        id: 'ContactDetails.emailVerifiedSuccess',
        defaultMessage: 'Email verified successfully!',
    },
    emailVerificationFail: {
        id: 'ContactDetails.emailVerificationFail',
        defaultMessage: 'Email verification failed! Please try again or request a new code.',
    },
    emailResetFailed: {
        id: 'ContactDetails.emailVerificationFail',
        defaultMessage: 'Email reset failed!',
    },
    contactDetails: {
        id: 'ContactDetails.contactDetailsVerification',
        defaultMessage: 'We need to check your Power of Attorney',
    },
    dataProtectionNotification: {
        id: 'ContactDetails.dataProtectionNotification',
        defaultMessage: 'When sending e-mails, the data to be transmitted between the bank and your e-mail service provider is encrypted in principle (TLS transport encryption). Please note that your e-mail service provider has the technical ability to gain knowledge of the content.',
    },
    loginViaUsualMethod: {
        id: 'ContactDetails.loginViaUsualMethod',
        defaultMessage: 'Please login via your usual login method',
    },
    reLogin: {
        id: 'ContactDetails.reLogin',
        defaultMessage: 'Please click the button below to re-login',
    },
});
