import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
import { Button, Modal, ReusableSvg } from 'components';
import { globalMessages } from 'i18n/messages';
import { FlexContainer } from 'shared/styled/Layout';
import { userSelector } from 'store';
import { notificationSuccessHandler } from 'utils/notificationHandlers';
import { ContactUsForm } from 'views';
import { BusinessHoursInfoWrapper, ContactUsWrapper, BusinessHoursContainer, } from './ContactUsView.styles';
export const ContactUsView = () => {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const { language } = useSelector(userSelector);
    const [showContactUsModal, setShowContactUsModal] = useState(false);
    const [visibilityOfContactUsModal, setVisibilityOfContactUsModal] = useState(true); // needed for screenshot functionality
    const [displayBusinessHoursInfo, setDisplayBusinessHoursInfo] = useState(false);
    const openContactUsModal = () => {
        setShowContactUsModal(true);
    };
    const onCloseModal = () => {
        setShowContactUsModal(false);
        setDisplayBusinessHoursInfo(false);
    };
    const onToggleModalVisibility = () => {
        setVisibilityOfContactUsModal((prevValue) => !prevValue);
    };
    const onSubmit = () => {
        setDisplayBusinessHoursInfo(true);
        notificationSuccessHandler(formatMessage(globalMessages.successForm));
    };
    return (React.createElement(ContactUsWrapper, null,
        React.createElement(ReusableSvg, { width: "54px", height: "54px", Icon: MessageIcon, onClick: openContactUsModal, title: formatMessage(globalMessages.contactUs), cursor: "pointer" }),
        React.createElement(Modal, { name: 'Business Hours Info Modal', language: language, isOpen: showContactUsModal, isVisible: visibilityOfContactUsModal, displayDefaultActionButtons: false, closeModal: onCloseModal, dismissOnSubmit: false, heading: displayBusinessHoursInfo ? undefined : formatMessage(globalMessages.contactUs), variant: "large", padding: "2%" }, displayBusinessHoursInfo ? (React.createElement(BusinessHoursInfoWrapper, null,
            formatMessage(globalMessages.businessHoursNotification, {
                businessHours: (React.createElement(BusinessHoursContainer, null, formatMessage(globalMessages.mondayThroughFridayBusinessHours))),
            }),
            React.createElement(FlexContainer, { justifyContent: "center", padding: `${theme.spacing(2)} 0 0 0` },
                React.createElement(Button, { variant: "primary", onClick: onCloseModal }, formatMessage(globalMessages.continue))))) : (React.createElement(ContactUsForm, { onSubmit: onSubmit, closeModal: onCloseModal, toggleModalVisibility: onToggleModalVisibility, infoText: formatMessage(globalMessages.howMayWeHelpYou), placeholder: formatMessage(globalMessages.brieflyDescribeQuery) })))));
};
export default ContactUsView;
