export const ROUTES = {
    LANDING_PAGE: '/',
    CONTACT_DETAILS: '/contact-details',
    ACCOUNT_SELECTION: '/account-selection',
    EMAIL_VERIFICATION: '/email-verification',
    ONBOARDING_REQUESTS: '/onboarding-requests',
    ONBOARDING_FORMS: '/onboarding-forms',
    UNSUPPORTED_BROWSER: '/unsupported-browser',
    UNSUPPORTED_USER: '/unsupported-user',
    TOURIST_USERS: '/guests',
    TOURIST_COMPLETED: '/guests-completed',
    ENVELOPES_VALIDATION: '/envelopes-status',
    ONBOARDING_STATUS: '/onboarding-status',
    LOGIN_PORTALS: '/login-portals',
    LOGIN_FAILED: '/login-failed',
    USER_FAILED: '/user-failed',
    COOKIES_DISABLED: '/cookies-disabled',
};
export const ERRORED_ROUTES = [
    ROUTES.LOGIN_PORTALS,
    ROUTES.LOGIN_FAILED,
    ROUTES.USER_FAILED,
    ROUTES.UNSUPPORTED_BROWSER,
    ROUTES.COOKIES_DISABLED,
];
export const ROUTES_WITH_CONTACT_US = [
    ROUTES.LANDING_PAGE,
    ROUTES.CONTACT_DETAILS,
    ROUTES.ACCOUNT_SELECTION,
    ROUTES.EMAIL_VERIFICATION,
    ROUTES.ONBOARDING_REQUESTS,
    ROUTES.ONBOARDING_FORMS,
    ROUTES.UNSUPPORTED_BROWSER,
    ROUTES.ENVELOPES_VALIDATION,
    ROUTES.ONBOARDING_STATUS,
    ROUTES.LOGIN_FAILED,
    ROUTES.USER_FAILED,
    ROUTES.TOURIST_USERS,
    ROUTES.TOURIST_COMPLETED,
];
export const TOURIST_USERS_RELATED_ROUTES = [
    ROUTES.TOURIST_USERS,
    ROUTES.TOURIST_COMPLETED,
];
