var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import ReactSelect, { components, } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/ArrowDownIcon.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { Layer, ReusableSvg } from 'components';
import { globalMessages } from 'i18n/messages';
import { StyledError } from 'shared/styled/Layout';
import { FormikSelect } from './FormikSelect';
import { StyledSelectControl, StyledSelectLabel, StyledOption, StyledSelectContainer, StyledSelectMenu, StyledSingleValue, StyledLabelWrapper, StyledSelectIconWrapper, } from './Select.styles';
export const defaultStyles = {
    container: (provided) => (Object.assign({}, provided)),
    valueContainer: (provided) => (Object.assign(Object.assign({}, provided), { padding: '2px 0', flexDirection: 'column', alignItems: 'flex-start' })),
    singleValue: (provided) => (Object.assign(Object.assign({}, provided), { margin: '0' })),
    multiValue: () => ({ backgroundColor: 'transparent' }),
    multiValueRemove: () => ({ display: 'none' }),
    indicatorsContainer: () => ({ display: 'none' }),
    placeholder: () => ({ display: 'none' }),
    cleadIndicator: () => ({ display: 'none' }),
    indicatorSeparator: () => ({ display: 'none' }),
    control: () => ({}),
    menu: () => ({}),
    menuList: () => ({}),
    option: () => ({}),
};
const CustomIndicatorsContainer = ({ selectProps }) => {
    if (!selectProps.showArrowDownIcon) {
        return null;
    }
    return React.createElement(ArrowDownIcon, null);
};
const CustomValue = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (React.createElement(components.SingleValue, Object.assign({}, props),
        React.createElement(StyledSingleValue, { variantConfig: props.selectProps.variantConfig }, children)));
};
const CustomMenu = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (React.createElement(components.Menu, Object.assign({}, props),
        React.createElement(StyledSelectMenu, { variantConfig: props.selectProps.variantConfig, menuMaxHeight: props.selectProps.maxMenuHeight }, children)));
};
const CustomOption = (_a) => {
    var _b;
    var { children } = _a, props = __rest(_a, ["children"]);
    return (React.createElement(components.Option, Object.assign({}, props),
        React.createElement(StyledOption, { isFocused: props.isFocused, 
            // @ts-ignore @TODO revisit
            isDisabled: Boolean((_b = props.data) === null || _b === void 0 ? void 0 : _b.isDisabled) }, children)));
};
const CustomContainer = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    return (React.createElement(StyledSelectContainer, { isDisabled: props.isDisabled, reduceOpacity: props.selectProps.reduceOpacity }, children));
};
const CustomControl = (_a) => {
    var _b;
    var { children } = _a, props = __rest(_a, ["children"]);
    const { formatMessage } = useIntl();
    const iconWrapperRef = useRef(null);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const toggleTooltipOpenState = () => {
        setIsTooltipOpen(!isTooltipOpen);
    };
    return (React.createElement(components.Control, Object.assign({}, props, { innerProps: {
            onMouseDown: (event) => {
                var _a, _b, _c;
                if ((_a = iconWrapperRef === null || iconWrapperRef === void 0 ? void 0 : iconWrapperRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)) {
                    return;
                }
                (_c = (_b = props.innerProps).onMouseDown) === null || _c === void 0 ? void 0 : _c.call(_b, event);
            },
        } }),
        React.createElement(StyledLabelWrapper, { hasValue: props.hasValue, menuIsOpen: props.menuIsOpen, keepLabelAboveSelect: props.selectProps.keepLabelAboveSelect, variantConfig: props.selectProps.variantConfig, error: props.selectProps.error },
            React.createElement(StyledSelectLabel, { hasValue: props.hasValue, menuIsOpen: props.menuIsOpen, variantConfig: props.selectProps.variantConfig, tooltipText: props.selectProps.tooltipText }, props.selectProps.label),
            props.selectProps.tooltipText && (React.createElement(StyledSelectIconWrapper, { ref: iconWrapperRef },
                React.createElement(ReusableSvg, { onClick: toggleTooltipOpenState, Icon: InfoIcon, width: "14px", height: "14px", hoverable: true }),
                React.createElement(Layer, { name: props.selectProps.label || props.selectProps.tooltipText, closeLinkLabel: props.selectProps.label || '', open: isTooltipOpen, onCloseClick: toggleTooltipOpenState }, props.selectProps.tooltipText)))),
        React.createElement(StyledSelectControl, { "data-testid": "select", variantConfig: props.selectProps.variantConfig, hasValue: props.hasValue, menuIsOpen: props.menuIsOpen, error: props.selectProps.error, enabledBlinkingBorder: props.selectProps.enabledBlinkingBorder }, children),
        props.selectProps.error && (React.createElement(StyledError, null, typeof props.selectProps.errorText === 'string'
            ? props.selectProps.errorText
            : formatMessage((_b = props.selectProps.errorText) !== null && _b !== void 0 ? _b : globalMessages.invalidEmail)))));
};
export const defaultComponents = {
    IndicatorsContainer: CustomIndicatorsContainer,
    SelectContainer: CustomContainer,
    Control: CustomControl,
    Option: CustomOption,
    Menu: CustomMenu,
    SingleValue: CustomValue,
    MultiValue: CustomValue,
};
export const BasicSelect = (_a) => {
    var { isMulti, closeMenuOnSelect, isClearable, isSearchable = false, variantConfig = {
        menuBackground: 'white',
        inputBackground: 'white',
        size: 'small',
    }, isCreatableSelect = false, captureMenuScroll = false, // https://github.com/JedWatson/react-select/issues/4129 - in order to have scrollable list
    menuShouldScrollIntoView = true, components, styles, defaultMenuIsOpen = false, onChange, onMenuOpen, onMenuClose, showArrowDownIcon = true } = _a, props = __rest(_a, ["isMulti", "closeMenuOnSelect", "isClearable", "isSearchable", "variantConfig", "isCreatableSelect", "captureMenuScroll", "menuShouldScrollIntoView", "components", "styles", "defaultMenuIsOpen", "onChange", "onMenuOpen", "onMenuClose", "showArrowDownIcon"]);
    const Select = isCreatableSelect ? CreatableSelect : ReactSelect;
    const [isSelectOpen, setIsSelectOpen] = useState(defaultMenuIsOpen);
    const handleChange = (newValue, actionMeta) => {
        if (props.isDisabled) {
            return;
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(newValue, actionMeta);
        setIsSelectOpen(Boolean(newValue === null || newValue === void 0 ? void 0 : newValue.keepOpen));
    };
    const handleMenuOpen = () => {
        setIsSelectOpen(true);
        onMenuOpen === null || onMenuOpen === void 0 ? void 0 : onMenuOpen();
    };
    const handleMenuClose = () => {
        setIsSelectOpen(false);
        onMenuClose === null || onMenuClose === void 0 ? void 0 : onMenuClose();
    };
    return (React.createElement(Select, Object.assign({ components: Object.assign(Object.assign({}, defaultComponents), components), styles: Object.assign(Object.assign({}, defaultStyles), styles), isMulti: isMulti, closeMenuOnSelect: closeMenuOnSelect, isSearchable: isSelectOpen && isSearchable, isClearable: isClearable, captureMenuScroll: captureMenuScroll, variantConfig: variantConfig, onChange: handleChange, menuShouldScrollIntoView: menuShouldScrollIntoView, defaultMenuIsOpen: defaultMenuIsOpen, menuIsOpen: isSelectOpen, onMenuOpen: handleMenuOpen, onMenuClose: handleMenuClose, onBlur: handleMenuClose, controlShouldRenderValue: isSearchable || isCreatableSelect ? !isSelectOpen : true, showArrowDownIcon: showArrowDownIcon }, props)));
};
const Select = Object.assign(BasicSelect, { Formik: FormikSelect });
export default Select;
