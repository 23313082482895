import { createSlice } from '@reduxjs/toolkit';
import { LEGAL_FORM_ACCEPTANCE_TYPE_MAPPER, SIGNATORY_SOURCE_MAPPER } from 'utils/mappers';
const EMPTY_FORM = {
    identifier: '',
    typeId: '',
    isGlobal: false,
    title: { en: '', de: '' },
    acceptanceType: LEGAL_FORM_ACCEPTANCE_TYPE_MAPPER.UNKNOWN,
    completed: false,
    availableSignatories: [],
    signatorySource: SIGNATORY_SOURCE_MAPPER.UNKNOWN,
    taxIdRelevant: false,
    errors: {},
    index: -1,
    isFirst: true,
    isLast: false,
};
const initialState = {
    forms: [],
    selectedForm: EMPTY_FORM,
    envelopes: undefined,
    docuSignFormIsValid: undefined,
    formsLegalDocumentationInfoShouldBeDisplayed: true,
};
export const formsStateSlice = createSlice({
    name: 'formsState',
    initialState,
    reducers: {
        setForms: (state, action) => {
            state.forms = action.payload;
        },
        setSelectedForm: (state, action) => {
            const index = state.forms.findIndex((f) => f.identifier === action.payload.identifier);
            const isFirst = index === 0;
            const isLast = index === state.forms.length - 1;
            state.selectedForm = Object.assign(Object.assign({}, action.payload), { index, isFirst, isLast });
        },
        selectNextFormToValidate: (state) => {
            let newIndex = Math.min(state.forms.length - 1, state.selectedForm.index + 1);
            if (newIndex === state.selectedForm.index &&
                state.forms.slice(0, -1).some((form) => !form.completed)) {
                newIndex = state.forms.findIndex((form) => !form.completed);
            }
            state.selectedForm = Object.assign(Object.assign({}, state.forms[newIndex]), { isFirst: newIndex === 0, isLast: newIndex === state.forms.length - 1, index: newIndex });
        },
        selectPreviousForm: (state) => {
            const newIndex = Math.max(0, state.selectedForm.index - 1);
            state.selectedForm = Object.assign(Object.assign({}, state.forms[newIndex]), { isFirst: newIndex === 0, isLast: newIndex === state.forms.length - 1, index: newIndex });
        },
        resetSelectedForm: (state) => {
            state.selectedForm = EMPTY_FORM;
        },
        setFormsEnvelopes: (state, action) => {
            state.envelopes = action.payload;
        },
        setDocuSignFormIsValid: (state, action) => {
            state.docuSignFormIsValid = action.payload;
        },
        setFormsLegalDocumentationInfoShouldBeDisplayed: (state, action) => {
            state.formsLegalDocumentationInfoShouldBeDisplayed = action.payload;
        },
    },
});
export const { setForms, setSelectedForm, selectNextFormToValidate, selectPreviousForm, resetSelectedForm, setFormsEnvelopes, setDocuSignFormIsValid, setFormsLegalDocumentationInfoShouldBeDisplayed, } = formsStateSlice.actions;
export default formsStateSlice.reducer;
