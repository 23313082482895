import { styled } from 'styled-components';
export const StyledEmailOption = styled.div `
	display: flex;
	align-items: center;
	width: 100%;
	padding: ${({ theme }) => `${theme.spacing(1.25)} ${theme.spacing(1.5)}`};
	font-size: ${({ theme }) => theme.fontSizes[13]};
	text-align: left;
	background-color: ${({ theme }) => theme.colors.white};

	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.colors.lightGrey};
		font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
	}
`;
