import React from 'react';
import { useField } from 'formik';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import { StyledToggle, StyledToggleContainer, StyledToggleControl, StyledToggleInnerLabel, StyledToggleInput, StyledToggleLabel, StyledToggleOuterLabel, } from 'components/Toggle/Toggle.styles';
export const FormikToggle = ({ name, leftLabel, rightLabel, label, textColor, disabled = false, small = false, defaultValue = false, greyOutUnchecked = false, }) => {
    var _a;
    const [field] = useField({
        name,
        type: 'checkbox',
    });
    return (React.createElement(StyledToggleContainer, { disabled: disabled, "data-testid": "toggle-root", small: small },
        React.createElement(StyledToggleInput, Object.assign({}, field, { type: "checkbox", id: name, disabled: disabled, onChange: !disabled ? field.onChange : undefined, value: (_a = field.value) !== null && _a !== void 0 ? _a : defaultValue })),
        React.createElement(StyledToggleLabel, { disabled: disabled, htmlFor: name, "data-testid": "label" },
            leftLabel && (React.createElement(StyledToggleInnerLabel, { textColor: textColor }, leftLabel)),
            React.createElement(StyledToggle, { greyedOut: !field.checked && greyOutUnchecked, borderWidth: 4 },
                React.createElement(StyledToggleControl, null, small && (field.checked ? React.createElement(CheckIcon, null) : React.createElement(CloseIcon, null)))),
            rightLabel && (React.createElement(StyledToggleInnerLabel, { textColor: textColor }, rightLabel))),
        label && React.createElement(StyledToggleOuterLabel, { htmlFor: name }, label)));
};
export default FormikToggle;
