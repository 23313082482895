import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';
import { globalMessages, onboardingRequestsMessages } from 'i18n/messages';
export const emailVerificationCodeLength = 8;
export const EmptySchema = Yup.object().shape({});
export const EmailSchema = Yup.object().shape({
    email: Yup.string()
        .email(globalMessages.invalidEmail)
        .required(onboardingRequestsMessages.requiredField),
});
export const PhoneSchema = Yup.object().shape({
    phone: Yup.string()
        .test('phone', globalMessages.phoneNumberNotValid, (value = '') => isValidPhoneNumber(value))
        .required(onboardingRequestsMessages.requiredField),
});
export const MobileSchema = Yup.object().shape({
    mobile: Yup.string()
        .test('mobile', globalMessages.phoneNumberNotValid, (value = '') => isValidPhoneNumber(value))
        .required(onboardingRequestsMessages.requiredField),
});
export const EmailVerificationSchema = Yup.object().shape({
    code: Yup.string()
        .min(emailVerificationCodeLength)
        .max(emailVerificationCodeLength)
        .required(onboardingRequestsMessages.requiredField),
});
export const ContactDetailsSchema = (withPhone) => {
    return Yup.lazy(() => {
        if (!withPhone) {
            return EmailSchema;
        }
        return EmailSchema.concat(PhoneSchema);
    });
};
