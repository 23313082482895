import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'constants/index';
export const ProtectedRoute = ({ children, canActivate = false, redirectTo = ROUTES.LOGIN_PORTALS, }) => {
    const { pathname } = useLocation();
    if (canActivate === false && redirectTo === pathname) {
        throw new Error(`Trying to access same ${pathname} route!`);
    }
    return React.createElement(React.Fragment, null, canActivate ? children : React.createElement(Navigate, { to: redirectTo }));
};
export default ProtectedRoute;
