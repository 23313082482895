// ========================================================================
// THEME/Z-INDEX
// ========================================================================
export default {
    notification: 300,
    loader: 250,
    tooltip: 220,
    toolbar: 205,
    modal: 201, // +1 from Mantine Modals
    overlays: 100,
    buttonSelect: 50,
    dropdown: 25,
    href: 5,
    icon: 10,
};
