import React, { forwardRef } from 'react';
import { LsgSvgIconWrapper, StyledLsgIcon, SvgContainer } from './ReusableSvg.styles';
export const ReusableSvg = forwardRef(function ReusableSvg(props, ref) {
    const { Icon, hoverable = false, pulsate = false, withYellowBackground = false, lsgIcon, title, cursor, hidden, } = props;
    return lsgIcon ? (React.createElement(SvgContainer, Object.assign({}, props, { ref: ref, cursor: cursor, hoverable: hoverable, withYellowBackground: withYellowBackground, hidden: hidden }),
        React.createElement(LsgSvgIconWrapper, null,
            React.createElement(StyledLsgIcon, { icon: props.icon, 
                // @ts-ignore
                color: props.color, title: title })))) : (React.createElement(SvgContainer, Object.assign({}, props, { ref: ref, cursor: cursor, pulsate: pulsate, hoverable: hoverable, withYellowBackground: withYellowBackground, hidden: hidden }), Icon && React.createElement(Icon, null)));
});
export default ReusableSvg;
