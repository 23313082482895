// selectors
export const ROOT_ID = 'root';
export const PORTAL_ID = 'portal';
export const ONBOARDING_FORMS_FETCHING_DELAY = 'FORMS_FETCHING_DELAYED_TOAST';
export const NO_INTENET_CONNECTION_TOAST_ID = 'NO_INTERNET_CONNECTION_TOAST';
export const SERVER_UNAVAILABLE_TOAST_ID = 'SERVICE_UNAVAILABLE_TOAST';
export const SOMETHING_WENT_WRONG_TOAST_ID = 'SOMETHING_WENT_WRONG_TOAST';
export const MY_PROFILE_FORM_ID = 'MY_PROFILE_FORM';
export const CLICK_THROUGH_FORM_ID = 'CLICK_THROUGH_FORM';
export const DOCU_SIGN_FORM_ID = 'DOCU_SIGN_FORM';
export const DOCU_SIGN_FORM_ERROR_TOAST = 'DOCU_SIGN_FORM_ERROR_TOAST';
export const DOCU_SIGN_SINGLE_SIGNATORY_FORM_ID = 'DOCU_SIGN_SINGLE_SIGNATORY_FORM_ID';
export const VERIFY_EMAIL_TOAST_ID = 'VERIFY_EMAIL_TOAST_ID';
export const NEW_VERIFY_EMAIL_CODE_TOAST_ID = 'NEW_VERIFY_EMAIL_CODE_TOAST_ID';
export const LAYER_ELEMENT_CLASSNAME = 'layer-element';
export const MODAL_ELEMENT_CLASSNAME = 'modal-element';
// fixed height elements
export const HEADER_HEIGHT = '56px';
export const NAV_HEIGHT = '60px';
export const FOOTER_HEIGHT = '40px';
