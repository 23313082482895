import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ProgressBarElement, ProgressBarText, ProgressBarWrapper } from './ProgressBar.styles';
export const ProgressBar = ({ max = 100, message, currentValue, width = 295, color = 'yellow', textColor = 'inherit', backgroundColor = 'grey', onlyPercentLabel = false, isVisible = true, }) => {
    const [percentage, setPercentage] = useState((currentValue / max) * 100);
    const { formatMessage } = useIntl();
    useEffect(() => {
        setPercentage((currentValue / max) * 100);
    }, [currentValue, max]);
    if (!isVisible) {
        return null;
    }
    return (React.createElement(ProgressBarWrapper, { onlyPercentLabel: onlyPercentLabel, width: width },
        React.createElement(ProgressBarText, { textColor: textColor }, onlyPercentLabel ? `${percentage}%` : formatMessage(message, { percentage: currentValue })),
        React.createElement(ProgressBarElement, { filled: percentage, color: color, background: backgroundColor })));
};
export default ProgressBar;
