import { api, requester } from 'api/shared';
const LOGGER_ENDPOINTS = {
    sendLog: () => '/logging',
};
const loggerApi = api.injectEndpoints({
    endpoints: (build) => ({
        sendLog: build.mutation({
            query: (data) => requester.post(LOGGER_ENDPOINTS.sendLog(), data),
        }),
    }),
});
export const loggerApiEndpoints = loggerApi.endpoints;
export const { useSendLogMutation } = loggerApi;
