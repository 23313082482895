import { styled } from 'styled-components';
import { FlexContainer } from 'shared/styled/Layout';
export const ToastMessageTitle = styled.div `
	font-size: ${({ theme }) => theme.fontSizes[17]};
	font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
`;
export const ToastMessageText = styled.div ``;
export const ToastMessageWrapper = styled(FlexContainer) `
	align-items: center;
	row-gap: ${({ theme }) => theme.spacing(3)};
	background: ${({ theme }) => theme.colors.lightGrey};
	padding: ${({ theme }) => theme.spacing(2)};
	border-radius: ${({ theme }) => theme.spacing(2)};
`;
export const ToastIconWrapper = styled.div `
	display: flex;
	justify-content: center;
	width: 44px;
	height: 44px;
	border-radius: 6px;
	background: ${({ theme, variant }) => variant === 'success'
    ? theme.colors.lightGraySecondary
    : variant === 'warning'
        ? theme.colors.orange
        : theme.colors.red};
	margin-right: ${({ theme }) => theme.spacing(1)};
`;
export const ToastCloseIconWrapper = styled.div `
	margin-left: auto;
	margin-bottom: auto;
`;
