import styled, { css } from 'styled-components';
export const ItemLabel = styled.p `
	font-size: ${({ theme }) => theme.fontSizes[13]};
	line-height: 13px;
	margin-bottom: ${({ theme }) => theme.spacing(1)};
	padding-right: ${({ theme }) => theme.spacing(0.35)};
	${({ disabled }) => disabled && disabledText}
`;
export const SectionHeading = styled.h3 `
	padding-bottom: ${({ theme, noSpacing }) => (noSpacing ? 0 : theme.spacing(3))};
	font-size: ${({ theme }) => theme.fontSizes[16]};
	font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
	${({ disabled }) => disabled && disabledText}
`;
const disabledText = css `
	color: ${({ theme }) => theme.colors.grey};
`;
export const HeadingWithInfoIconWrapper = styled.div `
	display: flex;
	align-items: center;
`;
export const TextContent = styled.span `
	color: ${({ color }) => color || 'inherit'};
	font-style: ${({ fontStyle }) => fontStyle || 'inherit'};
	font-size: ${({ theme, fontSize }) => fontSize || theme.fontSizes[12]};
	font-weight: ${({ fontWeight }) => fontWeight || 'inherit'};
	padding: ${({ theme, padding }) => padding || theme.spacing(0)};
	opacity: ${({ theme, grayOut }) => (grayOut ? theme.opacity[70] : theme.opacity[100])};
	cursor: ${({ cursor = 'inherit' }) => cursor};
	text-align: ${({ textAlign = 'auto' }) => textAlign};
	text-decoration: ${({ textDecoration = 'none' }) => textDecoration};
`;
