import { css } from 'styled-components';
export const datetime = css `
	.rdt {
		position: relative;
	}
	.rdtPicker {
		display: none;
		position: absolute;
		min-width: 250px;
		padding: ${({ theme }) => theme.spacing(1)};
		margin-top: ${({ theme }) => theme.spacing(0.5)};
		z-index: 10;
		background: ${({ theme }) => theme.colors.white};
		box-shadow: ${({ theme }) => theme.boxShadow};
	}

	.rdtOpen .rdtPicker {
		display: block;
	}

	.rdtStatic .rdtPicker {
		box-shadow: none;
		position: static;
	}

	.rdtPicker .rdtTimeToggle {
		text-align: center;
	}

	.rdtPicker table {
		width: 100%;
		margin: 0;
	}

	.rdtPicker td,
	.rdtPicker th {
		text-align: center;
		height: 33px;
		font-size: ${({ theme }) => theme.fontSizes[12]};
	}

	.rdtPicker td {
		cursor: pointer;
	}

	.rdtPicker td.rdtDay {
		border-right: 1px solid ${({ theme }) => theme.colors.bluePrimary};
		border-bottom: 1px solid ${({ theme }) => theme.colors.bluePrimary};

		&:first-child {
			border-left: 1px solid ${({ theme }) => theme.colors.bluePrimary};
		}
	}

	.rdtPicker td.rdtDay:hover,
	.rdtPicker td.rdtHour:hover,
	.rdtPicker td.rdtMinute:hover,
	.rdtPicker td.rdtSecond:hover,
	.rdtPicker .rdtTimeToggle:hover {
		background: ${({ theme }) => theme.colors.yellow};
		cursor: pointer;
	}

	.rdtPicker td.rdtOld,
	.rdtPicker td.rdtNew {
		opacity: ${({ theme }) => theme.opacity[30]};
	}

	.rdtPicker td.rdtToday:not(:hover) {
		position: relative;
		background-color: ${({ theme }) => theme.colors.bluePrimary};
	}

	.rdtPicker td.rdtActive,
	.rdtPicker td.rdtActive:hover {
		font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
	}

	.rdtPicker td.rdtActive.rdtToday:before {
		border-bottom-color: ${({ theme }) => theme.colors.white};
	}

	.rdtPicker td.rdtDisabled,
	.rdtPicker td.rdtDisabled:hover {
		background: none;
		cursor: not-allowed;
	}

	.rdtPicker td span.rdtDisabled,
	.rdtPicker td span.rdtDisabled:hover {
		background: none;
		cursor: not-allowed;
	}

	.rdtPicker .dow {
		width: 14.2857%;
		border-bottom: none;
		cursor: default;
		font-weight: ${({ theme }) => theme.typography.fontWeights.light};
		padding-bottom: ${({ theme }) => theme.spacing(2)};
		padding-top: ${({ theme }) => theme.spacing(2)};
	}

	.rdtPicker th.rdtSwitch {
		width: 100px;
	}

	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev {
		font-size: 15px;
		vertical-align: top;
	}

	.rdtPrev span,
	.rdtNext span {
		display: block;
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Chrome/Safari/Opera */
		-khtml-user-select: none; /* Konqueror */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
	}

	.rdtPicker th.rdtDisabled,
	.rdtPicker th.rdtDisabled:hover {
		background: none;
		cursor: not-allowed;
	}

	.rdtPicker thead tr:first-of-type th {
		cursor: pointer;
		height: auto;
		font-size: ${({ theme }) => theme.fontSizes[13]};
	}

	.rdtPicker tbody tr:first-child {
		border-top: 1px solid ${({ theme }) => theme.colors.bluePrimary};
	}

	.rdtPicker tfoot {
		display: none;
		border-top: 1px solid #f9f9f9;
	}

	.rdtPicker button {
		border: none;
		background: none;
		cursor: pointer;
	}

	.rdtPicker button:hover {
		background-color: ${({ theme }) => theme.colors.yellow};
	}

	.rdtPicker thead button {
		width: 100%;
		height: 100%;
	}

	td.rdtMonth,
	td.rdtYear {
		height: 50px;
		width: 25%;
		cursor: pointer;
	}

	td.rdtMonth:hover,
	td.rdtYear:hover {
		background: ${({ theme }) => theme.colors.yellow};
	}

	.rdtCounters {
		display: inline-block;
	}

	.rdtCounters > div {
		float: left;
	}

	.rdtCounter {
		height: 100px;
	}

	.rdtCounter {
		width: 40px;
	}

	.rdtCounterSeparator {
		line-height: 100px;
	}

	.rdtCounter .rdtBtn {
		height: 40%;
		line-height: 40px;
		cursor: pointer;
		display: block;

		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Chrome/Safari/Opera */
		-khtml-user-select: none; /* Konqueror */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
	}

	.rdtCounter .rdtBtn:hover {
		background: ${({ theme }) => theme.colors.yellow};
	}

	.rdtCounter .rdtCount {
		height: 20%;
		font-size: 1.2em;
	}

	.rdtMilli {
		vertical-align: middle;
		padding-left: 8px;
		width: 48px;
	}

	.rdtMilli input {
		width: 100%;
		font-size: 1.2em;
		margin-top: 37px;
	}

	.rdtTime td {
		cursor: default;
	}
`;
