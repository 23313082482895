import * as Yup from 'yup';
import { globalMessages, onboardingRequestsMessages } from 'i18n/messages';
export const ReportProblemSchema = Yup.object().shape({
    details: Yup.string().trim().required(onboardingRequestsMessages.requiredField),
});
const phoneRegExp = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
export const ContactUsSchema = Yup.object().shape({
    details: Yup.string().trim().required(onboardingRequestsMessages.requiredField),
    viaEmail: Yup.boolean().when('viaPhone', ([viaPhone], schema) => {
        return !viaPhone ? schema.oneOf([true]).required() : schema;
    }),
    email: Yup.string().when('viaEmail', ([viaEmail], schema) => {
        return viaEmail
            ? schema
                .trim()
                .email(globalMessages.invalidEmail)
                .required(onboardingRequestsMessages.requiredField)
            : schema.notRequired();
    }),
    viaPhone: Yup.boolean(),
    phone: Yup.string().when('viaPhone', ([viaPhone], schema) => {
        return viaPhone
            ? schema
                .trim()
                .required(onboardingRequestsMessages.requiredField)
                .matches(phoneRegExp, globalMessages.phoneNumberNotValid.defaultMessage)
            : schema.notRequired();
    }),
});
