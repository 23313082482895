export const modalSizes = {
    small: {
        width: 'clamp(40vw, 650px, 70vw)',
        height: 'auto',
        padding: '2%',
    },
    medium: {
        width: 'clamp(45vw, 800px, 80vw)',
        height: 'auto',
        padding: '3%',
    },
    large: {
        width: 'clamp(50vw, 900px, 80vw)',
        height: 'auto',
        padding: '3%',
    },
};
