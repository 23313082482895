// ========================================================================
// THEME/FONT-SIZES
// ========================================================================
import { pxToRem } from 'assets/styles/utils';
export const fontSizes = {
    10: pxToRem(10),
    11: pxToRem(11),
    12: pxToRem(12),
    13: pxToRem(13),
    14: pxToRem(14),
    15: pxToRem(15),
    16: pxToRem(16),
    17: pxToRem(17),
    18: pxToRem(18),
    20: pxToRem(20),
    22: pxToRem(22),
    24: pxToRem(24),
};
